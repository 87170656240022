import { Link } from "react-router-dom";
import img2 from "../Vision/word.png";

function Vision() {
  return (
    <div
      className="module module--text-picture module--space-big bg-black"
      data-name="text-picture"
    >
      <div className="module__content">
        <div className="text-picture text-picture--left ">
          <div className="text-picture__inner container ">
            {/* caption */}
            <div className="text-picture__caption">
              <div className="text-picture__uptitle">
                <div className="uptitle ">
                  <div className="icon uptitle__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-texport-logo" />
                    </svg>
                  </div>{" "}
                  About Us{" "}
                </div>
              </div>
              <div className="text-picture__title h2">
                     From Idea to Product{" "}
              </div>
              <div className="text-picture__text text">
                <p>
                   Serial production only begins after intensive research into
                    the design and material composition has been carried out and
                    practical tests have been passed. There, the clothing is meticulously and
                    elaborately handcrafted by trained workers.
                </p>
              </div>
              <div className="text-picture__cta">
                <Link
                  className="cta js-ga-track      js-ga4-track            "
                  to={"/vision"}
                >
                  <div className="icon cta__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-circle-arrow" />
                    </svg>
                  </div>{" "}
                  <span> Our Vision</span>
                </Link>
              </div>
            </div>
            {/* image */}
            <div className="text-picture__picture">
            
              <img
                alt=""
                className="desktop text-picture__image lazyloaded"
                src={img2}
              />
             
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vision;