import { useState } from "react";
import smoke from "../../../img/smoke.png";

function Product({ dataprod, selectslide }) {
  const widthprod = ((window.outerWidth-24)*0.6)-14;

  const [select, setselect] = useState(0);

  const handleSetCellState = (key) => {
    setselect(key);
  };

  return (
    <div
      className={
        selectslide === dataprod.id
          ? "product-slider__slide swiper-slide swiper-slide-visible swiper-slide-active"
          : selectslide + 1 == dataprod.id
          ? "product-slider__slide swiper-slide swiper-slide-next"
          : "product-slider__slide swiper-slide"
      }
      style={{ width: widthprod }}
    >
      <div className="product-slider__inner">
        <div
          className="product-slider__uptitle"
          style={{ transform: "translate3d(0px, 0px, 0px)" }}
        >
          <div className="uptitle ">
            <div className="icon uptitle__icon">
              <svg>
                <use xlinkHref="/symbols.svg#icon-texport-logo" />
              </svg>
            </div>{" "}
            NEW PRODUCTS – OUT NOW!{" "}
          </div>
        </div>
        <div className="product-slider__product">
          <div className="product-slider__product__inner">
            <div
              className="product-display  "
              data-product-display-settings="{ }"
            >
              <div
                className="product-display__main js-product-display__parallax"
                style={{
                  transform: "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
                  transformStyle: "preserve-3d",
                  backfaceVisibility: "hidden",
                  pointerEvents: "none",
                }}
              >
                <div
                  className="product-display__product-images"
                  data-depth={0}
                  style={{
                    transform: "translate3d(0px, 0px, 0px)",
                    transformStyle: "preserve-3d",
                    backfaceVisibility: "hidden",
                    position: "relative",
                    display: "block",
                    left: 0,
                    top: 0,
                  }}
                >
                  {dataprod.img.map((item, index) => {
                    return (
                      <div
                        className={
                          "product-display__image product-display__image--small js-product-display__image " +
                          (index === select ? "active" : "")
                        }
                        data-index={index}
                      >
                        <img className=" ls-is-cached lazyloaded" src={item} />
                      </div>
                    );
                  })}
                </div>
                <div
                  className="product-display__smoke js-product-display__smoke-container"
                  data-depth="0.08"
                  style={{
                    transform: "translate3d(1.2px, -2.6px, 0px)",
                    transformStyle: "preserve-3d",
                    backfaceVisibility: "hidden",
                    position: "absolute",
                    display: "block",
                    left: 0,
                    top: 0,
                  }}
                >
                  <img
                    className="js-product-display__smoke ls-is-cached lazyloaded"
                    alt="Smoke"
                    src={smoke}
                  />
                </div>
              </div>
              <div className="product-display__navigation product-display__navigation--6">
                <div className="product-display__curve product-display__curve--6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={382}
                    height={67}
                    viewBox="0 0 382 67"
                  >
                    <path
                      fill="none"
                      stroke="#C5C6C6"
                      d="M1 31c0 19.33 85.066 35 190 35s190-15.67 190-35c0-12.933-38.08-24.228-94.713-30.287"
                    />
                  </svg>
                  {dataprod.img.map((item, index) => {
                    return (
                      <div
                        className={
                          "product-display__curve__dot js-product-display__dot " +
                          (index === select ? "active" : "")
                        }
                        onClick={() => handleSetCellState(index)}
                        data-index={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="product-slider__content"
          style={{ transform: "translate3d(0px, 0px, 0px)" }}
        >
          <h3 className="product-slider__title h3">{dataprod.name}</h3>
          <div className="product-slider__cta">
            <a
              className="cta       js-ga4-track            "
            >
              <div className="icon cta__icon">
                <svg>
                  <use xlinkHref="/symbols.svg#icon-circle-arrow" />
                </svg>
              </div>{" "}
              <span> View product</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Product;
