import logo from "../../img/logo-footer.png"

function Footer() {
  return (
    <div className="module module--footer  " data-name="footer">
      <div className="module__content">
        <footer className="footer">
          <div className="footer__content container">
            <div>
              {/* logo */}
              <div className="footer__logo">
                <img 
                  className="footer__logo__image ls-is-cached lazyloaded"
                  src={logo}
                />
              </div>
           
            </div>
            {/* columns */}
            <div
              className="footer__columns"
              data-accordion-settings='{"until": "tablet-landscape"}'
            >
              <div className="footer__column">
                <div className="footer__column__title js-accordion__head">
                  <div className="footer__column__title__link uptitle">
                    Products{" "}
                  </div>
                  <div className="footer__column__title__plus" />
                </div>
                <div className="footer__column__content js-accordion__body">
                  {/* links */}
                  <div className="footer__column__links">
                    <a
                      className="footer__column__link js-ga-track js-ga4-track" 
                    >
                      Firewear{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track" 
                    >
                      Technologies{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track" 
                    >
                      Integrated Rescue System (IRS){" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track" 
                    >
                      Drag{" "}
                    </a>
                  </div>
                   
                </div>
              </div>
              <div className="footer__column">
                <div className="footer__column__title js-accordion__head">
                  <a
                    className="footer__column__title__link uptitle"
                    href="https://www.texport.at/en/service/"
                  >
                    Service{" "}
                  </a>
                  <div className="footer__column__title__plus" />
                </div>
                <div className="footer__column__content js-accordion__body">
                  {/* links */}
                  <div className="footer__column__links">
                    <a
                      className="footer__column__link js-ga-track js-ga4-track" 
                    >
                      Care{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track" 
                    >
                      Repair{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track"
                    >
                      Clean &amp; Care{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track"
                    >
                      Downloads{" "}
                    </a>
                  </div>
              
                </div>
              </div>
              <div className="footer__column">
                <div className="footer__column__title js-accordion__head">
                  <div className="footer__column__title__link uptitle">
                    About us{" "}
                  </div>
                  <div className="footer__column__title__plus" />
                </div>
                <div className="footer__column__content js-accordion__body">
                  {/* links */}
                  <div className="footer__column__links">
                    <a
                      className="footer__column__link js-ga-track js-ga4-track"
                     
                    >
                      The Company{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track"
                      
                    >
                      Vision{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track"
                    >
                      Team{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track"
  
                    >
                      Our Partners{" "}
                    </a>
                    <a
                      className="footer__column__link js-ga-track js-ga4-track"
                       >
                      Request for Sponsorship{" "}
                    </a>
                  </div>
              
                </div>
              </div>
              <div className="footer__column">
                <div className="footer__column__title js-accordion__head">
                  <div className="footer__column__title__link uptitle">
                    Contact{" "}
                  </div>
                  <div className="footer__column__title__plus" />
                </div>
                <div className="footer__column__content js-accordion__body">
                  {/* links */}
                  <div className="footer__column__links">
                    <div
                      className="footer__column__link js-ga-track js-ga4-track"
                      data-ga-event-category="Footer"
                      data-ga-event-label="Contact: "
                    ></div>
                  </div>
                  {/* text */}
                  <div className="footer__column__text text text--small">
                    <p>
                       Sondos Advanced Industry <sup>®</sup> 
                      <br />
                      ***************
                      <br />
                      *********, arabi saoudi
                    </p>
                  </div>
                  {/* phone */}
                  <div className="footer__column__info">
                    <div className="icon footer__column__info__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-phone" />
                      </svg>
                    </div>{" "}
                    <div className="text text--small">+ XX XXX XXX XXX </div>
                  </div>
                  {/* fax */}
                  <div className="footer__column__info">
                    <div className="icon footer__column__info__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-fax" />
                      </svg>
                    </div>{" "}
                    <div className="text text--small">+ XX XXX XXX XXX  </div>
                  </div>
                  {/* email */}
                </div>
              </div>
            </div>
          </div>
          {/* bottom */}
          <div className="footer__bottom">
            <div className="footer__bottom__content container">
              <div className="footer__bottom__links"></div>
              <div className="footer__bottom__legal">
                <a
                  className="footer__bottom__legal__link text text--small"
                >
                  General terms of business and delivery{" "}
                </a>
                <a
                  className="footer__bottom__legal__link text text--small"
                 
                >
                  Data Protection{" "}
                </a>
                <a
                  className="footer__bottom__legal__link text text--small"
                  
                >
                  Imprint{" "}
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
export default Footer;
