import { useEffect, useState } from "react";

function Details() {
  const [indexx, setindexx] = useState(0);
  const [opendselec, setopendselec] = useState(false);
  const [selecttext, setselecttext] = useState("Project");
  useEffect(() => {
    switch (indexx) {
      case 0:
        setselecttext("Project");
        break;
      case 1:
        setselecttext("Logistics");
        break;
      case 2:
        setselecttext("Public and Government Relations");
        break;
      case 3:
        setselecttext("General Administration");
        break;
      case 4:
        setselecttext("Human Resources");
        break;
      default:
        setselecttext("Project");
        break;
    }
  }, [indexx]);
  return (
    <div
      className="module module--team-tabs module--space-big "
      data-name="team-tabs"
    >
      <div className="module__content">
        <div
          className="team-tabs"
          data-tabs-settings='{"listenEvent": "teamTabsChanged-team-tabs-66d0b3b6c4983"}'
        >
          <div className="team-tabs__container container container--medium">
            <div className="tab-navigation ">
              <div className="tab-navigation__tabs">
                <div className="tab-navigation__tab">
                  <div
                    className={
                      "tab-navigation__tab__inner js-content-tabs__link " +
                      (indexx === 0 ? "active" : "")
                    }
                    onClick={() => setindexx(0)}
                  >
                    Project
                  </div>
                </div>
                <div className="tab-navigation__tab">
                  <div
                    className={
                      "tab-navigation__tab__inner js-content-tabs__link " +
                      (indexx === 1 ? "active" : "")
                    }
                    onClick={() => setindexx(1)}
                    data-slug="inside-sales-1"
                    data-name="main-team-tabs-66d0b3b6c4983"
                  >
                    Logistics
                  </div>
                </div>
                <div className="tab-navigation__tab">
                  <div
                    c
                    className={
                      "tab-navigation__tab__inner js-content-tabs__link " +
                      (indexx === 2 ? "active" : "")
                    }
                    onClick={() => setindexx(2)}
                    data-slug="area-sales-2"
                    data-name="main-team-tabs-66d0b3b6c4983"
                  >
                    Public and Government Relations
                  </div>
                </div>
                <div className="tab-navigation__tab">
                  <div
                    className={
                      "tab-navigation__tab__inner js-content-tabs__link " +
                      (indexx === 3 ? "active" : "")
                    }
                    onClick={() => setindexx(3)}
                  >
                    General Administration
                  </div>
                </div>

                <div className="tab-navigation__tab">
                  <div
                    className={
                      "tab-navigation__tab__inner js-content-tabs__link " +
                      (indexx === 4 ? "active" : "")
                    }
                    onClick={() => setindexx(4)}
                    data-slug="human-resources-5"
                    data-name="main-team-tabs-66d0b3b6c4983"
                  >
                    Human Resources{" "}
                  </div>
                </div>
              </div>
              <div className="tab-navigation__mobile-select">
                <div
                  className="mobile-select"
                  data-mobile-select-settings='{"changeEvent": "teamTabsChanged-team-tabs-66d0b3b6c4983"}'
                >
                  <div className="mobile-select__accordion">
                    <div
                      className={
                        "mobile-select__main js-accordion__head" +
                        (opendselec ? " is-open" : "")
                      }
                      onClick={() => setopendselec((opendselec) => !opendselec)}
                    >
                      <span className="js-mobile-select__main">
                        {selecttext}
                      </span>
                      <div className="icon mobile-select__icon">
                        <svg>
                          <use xlinkHref="/symbols.svg#icon-chevron-down" />
                        </svg>
                      </div>{" "}
                    </div>
                    <div
                      className="mobile-select__body js-accordion__body"
                      style={{ display: opendselec ? "block" : "none" }}
                    >
                      <div
                        className={
                          "mobile-select__option js-mobile-select__link " +
                          (indexx === 0 ? "disabled" : "")
                        }
                        onClick={() => {
                          setindexx(0);
                          setopendselec(false);
                        }}
                        data-slug="management-board-0"
                        data-name="main-team-tabs-66d0b3b6c4983"
                      >
                        Project
                      </div>
                      <div
                        className={
                          "mobile-select__option js-mobile-select__link " +
                          (indexx === 1 ? "disabled" : "")
                        }
                        onClick={() => {
                          setindexx(1);
                          setopendselec(false);
                        }}
                        data-slug="inside-sales-1"
                        data-name="main-team-tabs-66d0b3b6c4983"
                      >
                        Logistics
                      </div>
                      <div
                        className={
                          "mobile-select__option js-mobile-select__link " +
                          (indexx === 2 ? "disabled" : "")
                        }
                        onClick={() => {
                          setindexx(2);
                          setopendselec(false);
                        }}
                        data-slug="area-sales-2"
                        data-name="main-team-tabs-66d0b3b6c4983"
                      >
                        Public and Government Relations
                      </div>
                      <div
                        className={
                          "mobile-select__option js-mobile-select__link " +
                          (indexx === 3 ? "disabled" : "")
                        }
                        onClick={() => {
                          setindexx(3);
                          setopendselec(false);
                        }}
                        data-slug="marketing-3"
                        data-name="main-team-tabs-66d0b3b6c4983"
                      >
                        General Administration
                      </div>

                      <div
                        className={
                          "mobile-select__option js-mobile-select__link " +
                          (indexx === 4 ? "disabled" : "")
                        }
                        onClick={() => {
                          setindexx(4);
                          setopendselec(false);
                        }}
                        data-slug="human-resources-5"
                        data-name="main-team-tabs-66d0b3b6c4983"
                      >
                        Human Resources{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-tabs__main">
              <div
                className={
                  "team-tabs__category team-tabs__tab js-content-tabs__tab " +
                  (indexx === 0 ? "" : "hidden")
                }
                data-slug="management-board-0"
                data-name="main-team-tabs-66d0b3b6c4983"
              >
                <div className="team-tabs__section">
                  <div
                    className="team-tabs__subcategory team-tabs__tab js-content-tabs__tab "
                    data-slug="66d0b3b6bd788-0-0"
                    data-name="sub-0-0-team-tabs-66d0b3b6c4983"
                  >
                    <div className="team-tabs__person">
                      <div className="team-tabs__person__circle h5">PM </div>
                      <div className="team-tabs__person__content">
                        <div className="team-tabs__person__name h5">****</div>
                        <div className="team-tabs__person__position h6">
                          Manager
                        </div>
                        <div className="team-tabs__person__contact-details">
                          <div className="team-tabs__person__contact">
                            <div className="icon team-tabs__person__contact__icon">
                              <svg>
                                <use xlinkHref="/symbols.svg#icon-phone" />
                              </svg>
                            </div>{" "}
                            <div className="text">
                              <a href="tel:+966503335601">+966 503 335 601</a>
                            </div>
                            <div className="text">
                              <a href="tel:+966535945372">+966 535 945 372</a>
                            </div>
                          </div>
                          <div className="team-tabs__person__contact">
                            <div className="icon team-tabs__person__contact__icon">
                              <svg>
                                <use xlinkHref="/symbols.svg#icon-letter" />
                              </svg>
                            </div>{" "}
                            <div className="text">
                              <a href="mailto:a.alswaji@sondosfac.com">
                                a.alswaji@sondosfac.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "team-tabs__category team-tabs__tab js-content-tabs__tab " +
                  (indexx === 1 ? "" : "hidden")
                }
              >
                <div className="team-tabs__section">
                  <div
                    className="team-tabs__subcategory team-tabs__tab js-content-tabs__tab "
                    data-slug="66d0b3b6bd7e6-0-0"
                    data-name="sub-1-0-team-tabs-66d0b3b6c4983"
                  >
                    <div className="team-tabs__section">
                      <div
                        className="team-tabs__subcategory team-tabs__tab js-content-tabs__tab "
                        data-slug="66d0b3b6bd788-0-0"
                        data-name="sub-0-0-team-tabs-66d0b3b6c4983"
                      >
                        <div className="team-tabs__person">
                          <div className="team-tabs__person__circle h5">
                            LM{" "}
                          </div>
                          <div className="team-tabs__person__content">
                            <div className="team-tabs__person__name h5">
                              ****
                            </div>
                            <div className="team-tabs__person__position h6">
                              Manager
                            </div>
                            <div className="team-tabs__person__contact-details">
                              <div className="team-tabs__person__contact">
                                <div className="icon team-tabs__person__contact__icon">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-phone" />
                                  </svg>
                                </div>{" "}
                                <div className="text">
                                  <a href="tel:+966118103999">
                                    +966 118 103 999
                                  </a>
                                </div>
                              </div>
                              <div className="team-tabs__person__contact">
                                <div className="icon team-tabs__person__contact__icon">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-letter" />
                                  </svg>
                                </div>{" "}
                                <div className="text">
                                  <a href="mailto:logistic@sondosfac.com">
                                    logistic@sondosfac.com
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "team-tabs__category team-tabs__tab js-content-tabs__tab " +
                  (indexx === 2 ? "" : "hidden")
                }
                data-slug="area-sales-2"
                data-name="main-team-tabs-66d0b3b6c4983"
              >
                <div className="team-tabs__section">
                  <div className="team-tabs__subcategory team-tabs__tab js-content-tabs__tab ">
                    <div className="team-tabs__person">
                      <div className="team-tabs__person__circle h5">PM </div>
                      <div className="team-tabs__person__content">
                        <div className="team-tabs__person__name h5">****</div>
                        <div className="team-tabs__person__position h6">
                          Manager
                        </div>
                        <div className="team-tabs__person__contact-details">
                          <div className="team-tabs__person__contact">
                            <div className="icon team-tabs__person__contact__icon">
                              <svg>
                                <use xlinkHref="/symbols.svg#icon-phone" />
                              </svg>
                            </div>{" "}
                            <div className="text">
                              <a href="tel:+966544333144">+966 544 333 144</a>
                            </div>
                          </div>
                          <div className="team-tabs__person__contact">
                            <div className="icon team-tabs__person__contact__icon">
                              <svg>
                                <use xlinkHref="/symbols.svg#icon-letter" />
                              </svg>
                            </div>{" "}
                            <div className="text">
                              <a href="mailto:dakhil@sondosfac.com">
                                dakhil@sondosfac.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "team-tabs__category team-tabs__tab js-content-tabs__tab " +
                  (indexx === 3 ? "" : "hidden")
                }
                data-slug="marketing-3"
                data-name="main-team-tabs-66d0b3b6c4983"
              >
                <div className="team-tabs__section">
                  <div className="team-tabs__subcategory team-tabs__tab js-content-tabs__tab ">
                    <div className="team-tabs__person">
                      <div className="team-tabs__person__circle h5">GM </div>
                      <div className="team-tabs__person__content">
                        <div className="team-tabs__person__name h5">****</div>
                        <div className="team-tabs__person__position h6">
                          Manager
                        </div>
                        <div className="team-tabs__person__contact-details">
                          <div className="team-tabs__person__contact">
                            <div className="icon team-tabs__person__contact__icon">
                              <svg>
                                <use xlinkHref="/symbols.svg#icon-phone" />
                              </svg>
                            </div>{" "}
                            <div className="text">
                              <a href="tel:+966118103999">+966 118 103 999</a>
                            </div>
                          </div>
                          <div className="team-tabs__person__contact">
                            <div className="icon team-tabs__person__contact__icon">
                              <svg>
                                <use xlinkHref="/symbols.svg#icon-letter" />
                              </svg>
                            </div>{" "}
                            <div className="text">
                              <a href="mailto:info@sondosfac.com">
                                info@sondosfac.com
                              </a>
                            </div>
                          </div>
                          <div className="team-tabs__person__contact">
                            <div className="icon team-tabs__person__contact__icon">
                              <svg>
                                <use xlinkHref="/symbols.svg#icon-letter" />
                              </svg>
                            </div>{" "}
                            <div className="text">
                              <a href="mailto:info@sondosfac.net">
                                info@sondosfac.net
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  "team-tabs__category team-tabs__tab js-content-tabs__tab " +
                  (indexx === 4 ? "" : "hidden")
                }
                data-slug="human-resources-5"
                data-name="main-team-tabs-66d0b3b6c4983"
              >
                <div className="team-tabs__person">
                  <div className="team-tabs__person__circle h5">HR </div>
                  <div className="team-tabs__person__content">
                    <div className="team-tabs__person__name h5">****</div>
                    <div className="team-tabs__person__position h6">
                      Human Resources Director
                    </div>
                    <div className="team-tabs__person__contact-details">
                      <div className="team-tabs__person__contact">
                        <div className="icon team-tabs__person__contact__icon">
                          <svg>
                            <use xlinkHref="/symbols.svg#icon-phone" />
                          </svg>
                        </div>{" "}
                        <div className="text">
                          <a href="tel:+966118103999">+966 118 103 999</a>
                        </div>
                      </div>
                      <div className="team-tabs__person__contact">
                        <div className="icon team-tabs__person__contact__icon">
                          <svg>
                            <use xlinkHref="/symbols.svg#icon-letter" />
                          </svg>
                        </div>{" "}
                        <div className="text">
                          <a href="mailto:hr@sondosfac.com">hr@sondosfac.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Details;
