import imgmat from "../../../img/Firesuits/matimg.png";
import imgmat2 from "../../../img/Firesuits/imgxterm.png";
import { useState } from "react";
function Materialcomposition({ referense }) {
  const [indexlo, setindexlo] = useState(0);
  return (
    <div
      className="module module--accordion-image module--space-big "
      data-name="accordion-image"
      data-module-id="material-composition"
      ref={referense}
    >
      <div className="module__content">
        <div
          className="accordion accordion-image accordion-image--left"
          data-image-display-settings='{"indicatorListenerEvent" : "accordion-toggle-66cd8767adf5c" }'
        >
          <div className="accordion-image__container container">
            <div className="accordion-image__content">
              <div className="accordion-image__uptitle">
                <div className="uptitle ">
                  <div className="icon uptitle__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-texport-logo" />
                    </svg>
                  </div>{" "}
                  Material Composition{" "}
                </div>
              </div>
              <h2 className="accordion-image__title h3">
                The innovative material composition
              </h2>
            </div>
            {/* <div class="accordion-image__image js-accordion-image-swiper__image " id="x-treme"> */}
            <div
              className={
                "accordion-image__image js-accordion-image-swiper__image " +
                (indexlo != 0 ? "is-hidden" : "")
              }
              id="x-treme"
            >
              <img
                alt="X-Treme®"
                className="desktop lazyloaded"
                src={imgmat2}
              />
            </div>
            {/* <div class="accordion-image__image js-accordion-image-swiper__image is-hidden" id="x-treme-light"> */}
            <div
              className={
                "accordion-image__image js-accordion-image-swiper__image " +
                (indexlo != 1 ? "is-hidden" : "")
              }
              id="x-treme-light"
            >
              <img
                alt="X-Treme® light"
                className="desktop lazyloaded"
                src={imgmat}
              />
            </div>
            <div className="accordion-image__accordions">
              <div className="accordion-image__item">
                {/* <div class="accordion-image__item__head js-accordion__head is-open" data-indicator-id="x-treme"> */}
                <div
                  className={
                    "accordion-image__item__head js-accordion__head " +
                    (indexlo === 0 ? "is-open" : "")
                  }
                  onClick={() => setindexlo(0)}
                >
                  <div className="h6">X-Treme®</div>
                  <div className="accordion__head__expand-button" />
                </div>
                {/* <div class="accordion-image__item__body js-accordion__body" style="display:block;"> */}
                <div
                  className="accordion-image__item__body js-accordion__body"
                  style={{ display: indexlo === 0 ? "block" : "none" }}
                >
                  <div className="text">
                    When fighting fires, two serious dangers are direct flames
                    and heat radiation. If the heat penetrates the material of
                    the protective clothing, it can lead to burns to the body.
                    The thermal insulation can delay this decisively – by just
                    those few seconds that are required. Therefore, TEXPORT
                    <sup>®</sup> has developed the material composition X-TREME
                    <sup>®</sup>. X-TREME
                    <sup>®</sup> offers notably more protection and comfort
                    (thanks to its innovative material composition and new
                    high-tech intermediate layer) than is required by the
                    European EN 469 norm. The heat radiation is blocked for a
                    long period of time.
                  </div>
                </div>
              </div>
              <div className="accordion-image__item">
                {/* <div class="accordion-image__item__head js-accordion__head " data-indicator-id="x-treme-light"> */}
                <div
                  className={
                    "accordion-image__item__head js-accordion__head " +
                    (indexlo === 1 ? "is-open" : "")
                  }
                  onClick={() => setindexlo(1)}
                >
                  <div className="h6">X-Treme® light</div>
                  <div className="accordion__head__expand-button" />
                </div>
                {/* <div class="accordion-image__item__body js-accordion__body" > */}
                <div
                  className="accordion-image__item__body js-accordion__body"
                  style={{ display: indexlo === 1 ? "block" : "none" }}
                >
                  <div className="text">
                    X-TREME
                    <sup>
                      <sup>®</sup>
                    </sup>{" "}
                    light offers heat protection that significantly exceeds the
                    values set out in the European norm EN 469, whether in
                    direct contact with flames or heat radiation. When
                    developing X-TREME
                    <sup>
                      <sup>®</sup>
                    </sup>{" "}
                    light, the focus has been strongly on the weight. X-TREME
                    <sup>®</sup> light combines excellent protection with
                    significantly improved comfort, supported by the material’s
                    light weight.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Materialcomposition;
