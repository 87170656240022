function SubHeader() {
  return (
    <div
      className="module module--breadcrumbs module--space-bottom-small "
      data-name="breadcrumbs"
    >
      <div className="module__content">
        <div className="breadcrumbs container module--space-bottom-small">
          <div className="breadcrumbs__section breadcrumbs__section--mobile-active">
            {/* put chevron here */}
            <div className="icon breadcrumbs__icon">
              <svg>
                <use xlinkHref="/symbols.svg#icon-chevron-right" />
              </svg>
            </div>
            <a
              href="https://www.texport.at/en/collection/fire-twin/"
              className="breadcrumbs__item text "
            >
              Fire Twin{" "}
            </a>
          </div>
          <div className="breadcrumbs__section ">
            {/* put chevron here */}
            <div className="icon breadcrumbs__icon">
              <svg>
                <use xlinkHref="/symbols.svg#icon-chevron-right" />
              </svg>
            </div>
            <a
              href="https://www.texport.at/en/products/fire-twin-trousers/"
              className="breadcrumbs__item text is-active"
            >
              Fire Twin Trousers{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubHeader;
