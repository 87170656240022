import { useEffect, useRef, useState } from "react";
import Accordionimage from "./Accordionimage";
import Details from "./Details";
import Materialcomposition from "./Materialcomposition";
import Specifications from "./Specifications";
import Highlight from "./highlights";
import { useOnScreen } from "./useIsElementVisible";
import Info from "./info";
import SubHeader from "../../Header/SubHeader";

function FireSoot() {
  const SPref = useRef(null);
  const inforef = useRef(null);
  const Detref = useRef(null);
  const Matref = useRef(null);
  const Acctref = useRef(null);
  const Highttref = useRef(null);
  const [info, setinfo] = useState(true);
  const [spec, setspec] = useState(false);
  const [detai, setdetai] = useState(false);
  const [mate, setmate] = useState(false);
  const [acct, setacct] = useState(false);
  const [high, sethigh] = useState(false);
  const mattt = useOnScreen(Matref);
  const inf = useOnScreen(inforef);
  const deta = useOnScreen(Detref);
  const hev = useOnScreen(Highttref);
  const speci = useOnScreen(SPref);
  const [selectedfabric, setselectedfabric] = useState("0");

  useEffect(() => {
    sethigh(hev);
  }, [hev]);
  useEffect(() => {
    setspec(speci);
  }, [speci]);
  useEffect(() => {
    setmate(mattt);
  }, [mattt]);
  useEffect(() => {
    setdetai(deta);
  }, [deta]);
  useEffect(() => {
    setinfo(inf);
  }, [inf]);
  return (
    <>
      <SubHeader></SubHeader>
      <div class="dot-navigation__wrapper">
        <div className="dot-navigation">
          <div
            className={
              "dot-navigation__item js-dot-navigation__link " +
              (info ? "active" : "")
            }
            data-link-id="infos"
          >
            <div className="dot-navigation__dot" />
            <div className="dot-navigation__name h6">Remal Suit Trouser</div>
          </div>
          <div
            onClick={() => {
              SPref.current.scrollIntoView();
              // updateactive(1);
            }}
            className={
              "dot-navigation__item js-dot-navigation__link " +
              (spec ? "active" : "")
            }
            data-link-id="specifications"
          >
            <div className="dot-navigation__dot" />
            <div className="dot-navigation__name h6">Specifications</div>
          </div>
          <div
            className={
              "dot-navigation__item js-dot-navigation__link " +
              (detai ? "active" : "")
            }
            data-link-id="details"
            onClick={() => {
              Detref.current.scrollIntoView();
              // updateactive(2);
            }}
          >
            <div className="dot-navigation__dot" />
            <div className="dot-navigation__name h6">Details</div>
          </div>
          <div
            className={
              "dot-navigation__item js-dot-navigation__link " +
              (mate ? "active" : "")
            }
            data-link-id="material-composition"
            onClick={() => {
              Matref.current.scrollIntoView();
              // updateactive(3);
            }}
          >
            <div className="dot-navigation__dot" />
            <div className="dot-navigation__name h6">Material Composition</div>
          </div>
          <div
            className={
              "dot-navigation__item js-dot-navigation__link " +
              (high ? "active" : "")
            }
            data-link-id="highlights"
            onClick={() => {
              Highttref.current.scrollIntoView();
              // updateactive(4);
            }}
          >
            <div className="dot-navigation__dot" />
            <div className="dot-navigation__name h6">Highlights</div>
          </div>
        </div>
        {/* add */}
        <Info
          referense={inforef}
          selectedfabric={selectedfabric}
          setselectedfabric={setselectedfabric}
        ></Info>
        <Specifications
          referense={SPref}
          selectedfabric={selectedfabric}
          setselectedfabric={setselectedfabric}
        ></Specifications>
        <Details
          referense={Detref}
          selectedfabric={selectedfabric}
          setselectedfabric={setselectedfabric}
        ></Details>
        <Materialcomposition
          referense={Matref}
          selectedfabric={selectedfabric}
          setselectedfabric={setselectedfabric}
        ></Materialcomposition>
        <Accordionimage
          referense={Acctref}
          selectedfabric={selectedfabric}
          setselectedfabric={setselectedfabric}
        ></Accordionimage>
        <Highlight
          referense={Highttref}
          selectedfabric={selectedfabric}
          setselectedfabric={setselectedfabric}
        ></Highlight>
      </div>
    </>
  );
}
export default FireSoot;
