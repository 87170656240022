import Details from "./Details";
import Headerc from "./Headerc";

function Contact()
{
    return <>
     <Headerc></Headerc>
     <Details></Details>
    </>
}
export default Contact;