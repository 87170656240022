import { createRef, useEffect, useRef, useState } from "react";

function Details({ referense, selectedfabric }) {
  const [indexselect, setindex] = useState(0);
  const [elRefs, setElRefs] = useState([]);
  const data = [
    {
      id: 1,
      lib: "Adjustable waist",
      selectedindex: 0,
      description: "Individually adjustable",
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose_Detail_Bundweitenverstellung.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 2,
      lib: "Front fly",
      selectedindex: 2,
      description: "Velcro fastener",
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Frontoeffnung.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 3,
      lib: "Cargo pockets left",
      selectedindex: 0,
      description: "External, with multifunctional pocket and pen pocket",
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Cargotasche_links.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 4,
      lib: "Carabiner loops",
      description: "Internal",
      selectedindex: 0,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Karabinerlaschen.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 5,
      lib: "Knee reinforcement",
      description: "Abrasion protection",
      selectedindex: 0,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Knieverstaerkung.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 6,
      lib: "Cuff outside",
      description: "Abrasion protection, inner leg and hem",
      selectedindex: 0,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Beinabschluss_aussen.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 7,
      lib: "Cuff backside",
      selectedindex: 3,
      description: "Heel cut-out",
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Beinabschluss_hinten.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 8,
      lib: "Cuff inside",
      description: "Dirt protection",
      selectedindex: 2,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Beinabschluss_innen.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 9,
      lib: "Step in gusset",
      description: "Easier donning and doffing",
      selectedindex: 3,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Seitenkeilreissverschluss.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 10,
      lib: "Ergonomically preshaped legs",
      description: "Increased movability",
      selectedindex: 2,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Ergonomisch_vorgeformte_Hosenbeine.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 11,
      lib: "Hanger loop",
      description: "Internal, left right and back",
      selectedindex: 1,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Aufhaengeschlaufe.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 12,
      lib: "Brace system",
      description: "Removable",
      selectedindex: 3,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Hosentraeger.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 13,
      lib: "Inspection openings",
      description: "",
      selectedindex: 3,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose_Detail_Inspektionsoeffnung.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 14,
      lib: "Grab tab",
      description: "Ergonomically designed",
      selectedindex: 0,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Grifflaschen.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 15,
      lib: "Leasing collar",
      description: "Individual marking option",
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Leasingkoller.png",
      refitem: createRef(),
      selectedindex: 2,
      isactive: false,
    },
    {
      id: 16,
      lib: "Name tag",
      description: "Identification stripe",
      selectedindex: 3,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Namensflausch.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 17,
      lib: "Laundering protocol label",
      description: "Record keeping",
      selectedindex: 3,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Waschzyklenetikett.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 18,
      lib: "RFID",
      description: "Electronic identification system",
      selectedindex: 3,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose_Detail_RFID.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 19,
      lib: "Cargo pockets right",
      description: "External, with knife pocket and tool holder",
      selectedindex: 2,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Cargotasche_rechts.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 20,
      lib: "Knee pads",
      description: "",
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Kniepolster.png",
      refitem: createRef(),
      selectedindex: 1,
      isactive: false,
    },
    {
      id: 21,
      lib: "Quicklock",
      description: "Easy adjustment",
      selectedindex: 1,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Quicklock.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 22,
      lib: "Lumbar pad",
      description: "Back protection and cushioning",
      selectedindex: 3,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose__Detail_Lendenpolster.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 23,
      lib: "Drag rescue device",
      description: "Rescue strap, both sides",
      selectedindex: 2,
      img: "https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/details/Fire_Twin_Hose_Detail_Drag-System.png",
      refitem: createRef(),
      isactive: false,
    },
    {
      id: 24,
      lib: "Pockets",
      selectedindex: 1,
      description: "",
      img: "",
      refitem: createRef(),
      isactive: false,
    },
  ];
 
  useEffect(() => {
    // add or remove refs
    console.log(data.length);
    setElRefs((elRefs) =>
      Array(data.length)
        .fill()
        .map((_, i) => data[i])
    );
  }, [selectedfabric]);

  const listref = elRefs.map((item, i) => {
    const html1 = (
      <div className="product-details__item" ref={item.refitem}>
        <div
          onClick={(e) => {
            console.log(item.selectedindex);
            setindex(item.selectedindex);
            setElRefs((dd) => {
              const nextShapes = elRefs.map((shape) => {
                if (shape.id === item.id) {
                  // Return a new circle 50px below
                  return {
                    ...shape,
                    isactive: !shape.isactive,
                  };
                } else {
                  // Return a new circle 50px below
                  return { ...shape, isactive: false };
                }
              });
              return nextShapes;
            });
          }}
          className={
            "product-details__item__head js-accordion__head " +
            (item.isactive ? "is-open" : "")
          }
          data-indicator-id={item.lib.replace(" ", "-")}
        >
          <div className="product-details__item__title h6">{item.lib}</div>
          <div className="product-details__item__plus" />
        </div>
        <div
          className="product-details__item__body js-accordion__body"
          style={{ display: item.isactive ? "block" : "none" }}
        >
          <div className="product-details__item__content">
            {item.img === "" ? null : (
              <img
                src={item.img}
                alt="Adjustable waist"
                className="product-details__item__image ls-is-cached lazyloaded"
              />
            )}
            <div className="product-details__item__text text text--small">
              {item.description}
            </div>
          </div>
        </div>
      </div>
    );
    return { ht1: html1 };
  });
  return (
    <div
      ref={referense}
      className="module module--product-details module--space-big "
      data-name="product-details"
      data-module-id="details"
    >
      <div className="module__content">
        {/* <div class="product-details product-details--small" > */}
        {/* load related product script of currently active element */}
        <div className="product-details product-details--small">
          {/* <div class="product-details__main-item js-product-details__product " id="" > */}
          <div
            className="product-details__main-item js-product-details__product "
            id=""
          >
            <div className="product-details__container container">
              {/* product */}
              <div className="product-details__left">
                <div className="product-details__product">
                  {/* <div class="product-details__product__group js-product-details__content active" data-tab-id="fire-twin_drag_60a4e68d6b60a"> */}
                  <div
                    className="product-details__product__group js-product-details__content active"
                    data-tab-id="fire-twin_drag_60a4e68d6b60a"
                  >
                    <div
                      className="product-display product-display--nosmoke "
                      data-product-display-settings='{"indicatorEmitEvent":"indicator-click-66cd8767ace90", "indicatorListenerEvent": "accordion-toggle-66cd8767ace90"}'
                    >
                      {selectedfabric === "0" ? (
                        <div
                          className="product-display__main js-product-display__parallax"
                          style={{
                            transform:
                              "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
                            transformStyle: "preserve-3d",
                            backfaceVisibility: "hidden",
                            pointerEvents: "none",
                          }}
                        >
                          <div
                            className="product-display__product-images"
                            data-depth={0}
                            style={{
                              transform: "translate3d(0px, 0px, 0px)",
                              transformStyle: "preserve-3d",
                              backfaceVisibility: "hidden",
                              position: "relative",
                              display: "block",
                              left: 0,
                              top: 0,
                            }}
                          >
                            <div
                              className={
                                "product-display__image product-display__image--small js-product-display__image " +
                                (indexselect === 0 ? "active" : "")
                              }
                              data-index={0}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FL.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FL.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[13]
                                      ? elRefs[13].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="grab-tab"
                                  style={{ left: "44%", top: "31%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[13].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[13].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[4]
                                      ? elRefs[4].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="knee-reinforcement"
                                  style={{ left: "40%", top: "75%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[4].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[4].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[5]
                                      ? elRefs[5].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cuff-outside"
                                  style={{ left: "45%", top: "95%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[5].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[5].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[0]
                                      ? elRefs[0].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="adjustable-waist"
                                  style={{ left: "58%", top: "31%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[0].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[0].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[2]
                                      ? elRefs[2].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cargo-pockets-left"
                                  style={{ left: "62%", top: "58%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[2].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[2].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[3]
                                      ? elRefs[3].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="carabiner-loops"
                                  style={{ left: "62%", top: "51%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[3].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[3].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                "product-display__image product-display__image--small js-product-display__image " +
                                (indexselect === 1 ? "active" : "")
                              }
                              data-index={1}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_F.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_F.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[20]
                                      ? elRefs[10].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="quicklock"
                                  style={{ left: "61%", top: "20%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[20].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[20].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[10]
                                      ? elRefs[10].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="hanger-loop"
                                  style={{ left: "50%", top: "27%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[10].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[10].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[19]
                                      ? elRefs[19].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="knee-pads"
                                  style={{ left: "60%", top: "75%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[19].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[19].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[23]
                                      ? elRefs[23].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="pockets"
                                  style={{ left: "40%", top: "35%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[23].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[23].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                "product-display__image product-display__image--small js-product-display__image " +
                                (indexselect === 2 ? "active" : "")
                              }
                              data-index={2}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FR.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FR.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[1]
                                      ? elRefs[1].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="front-fly"
                                  style={{ left: "56%", top: "35%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[1].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[1].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[22]
                                      ? elRefs[22].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="drag-rescue-device"
                                  style={{ left: "39%", top: "63%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[22].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[22].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[9]
                                      ? elRefs[9].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="ergonomically-preshaped-legs"
                                  style={{ left: "56%", top: "70%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[9].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[9].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[18]
                                      ? elRefs[18].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cargo-pockets-right"
                                  style={{ left: "40%", top: "52%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[18].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[18].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[14]
                                      ? elRefs[14].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="leasing-collar"
                                  style={{ left: "45%", top: "38%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[14].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[14].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[7]
                                      ? elRefs[7].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cuff-inside"
                                  style={{ left: "58%", top: "92%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[7].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[7].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                "product-display__image product-display__image--small js-product-display__image " +
                                (indexselect === 3 ? "active" : "")
                              }
                              data-index={3}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_B.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_B.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[11]
                                      ? elRefs[11].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="brace-system"
                                  style={{ left: "51%", top: "15%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[11].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[11].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[21]
                                      ? elRefs[21].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="lumbar-pad"
                                  style={{ left: "50%", top: "33%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[21].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[21].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[6]
                                      ? elRefs[6].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cuff-backside"
                                  style={{ left: "52%", top: "98%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[6].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[6].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[15]
                                      ? elRefs[15].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="name-tag"
                                  style={{ left: "38%", top: "54%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[15].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[15].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[16]
                                      ? elRefs[16].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="laundering-protocol-label"
                                  style={{ left: "42%", top: "40%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[16].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[16].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[17]
                                      ? elRefs[17].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="rfid"
                                  style={{ left: "50%", top: "43%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[17].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[17].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[12]
                                      ? elRefs[12].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="inspection-openings"
                                  style={{ left: "50%", top: "38%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[12].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[12].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[8]
                                      ? elRefs[8].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="step-in-gusset"
                                  style={{ left: "38%", top: "90%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[8].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[8].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="product-display__main js-product-display__parallax"
                          style={{
                            transform:
                              "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
                            transformStyle: "preserve-3d",
                            backfaceVisibility: "hidden",
                            pointerEvents: "none",
                          }}
                        >
                          <div
                            className="product-display__product-images"
                            data-depth={0}
                            style={{
                              transform: "translate3d(0px, 0px, 0px)",
                              transformStyle: "preserve-3d",
                              backfaceVisibility: "hidden",
                              position: "relative",
                              display: "block",
                              left: 0,
                              top: 0,
                            }}
                          >
                            <div
                              className="product-display__image product-display__image--small js-product-display__image active"
                              data-index={0}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_FL.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_FL.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[13]
                                      ? elRefs[13].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="grab-tab"
                                  style={{ left: "44%", top: "31%" }}
                                  onClick={() => {
                                    elRefs[13].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[13].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[4]
                                      ? elRefs[4].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="knee-reinforcement"
                                  style={{ left: "40%", top: "75%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[4].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[4].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[5]
                                      ? elRefs[5].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cuff-outside"
                                  style={{ left: "45%", top: "95%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[5].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[5].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[0]
                                      ? elRefs[0].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="adjustable-waist"
                                  style={{ left: "58%", top: "31%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[0].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[0].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[2]
                                      ? elRefs[2].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cargo-pockets-left"
                                  style={{ left: "62%", top: "58%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[2].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[2].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[3]
                                      ? elRefs[3].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="carabiner-loops"
                                  style={{ left: "62%", top: "51%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[3].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[3].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="product-display__image product-display__image--small js-product-display__image "
                              data-index={1}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_F.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_F.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[20]
                                      ? elRefs[20].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="quicklock"
                                  style={{ left: "61%", top: "20%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[20].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[20].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[10]
                                      ? elRefs[10].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="hanger-loop"
                                  style={{ left: "50%", top: "27%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[10].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[10].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[19]
                                      ? elRefs[19].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="knee-pads"
                                  style={{ left: "60%", top: "75%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[19].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[19].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[23]
                                      ? elRefs[23].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="pockets"
                                  style={{ left: "40%", top: "35%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[23].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[23].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="product-display__image product-display__image--small js-product-display__image "
                              data-index={2}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_FR.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_FR.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[1]
                                      ? elRefs[1].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="front-fly"
                                  style={{ left: "56%", top: "35%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[1].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[1].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[22]
                                      ? elRefs[22].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="drag-rescue-device"
                                  style={{ left: "39%", top: "63%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[22].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[22].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[9]
                                      ? elRefs[9].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="ergonomically-preshaped-legs"
                                  style={{ left: "56%", top: "70%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[9].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[9].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[18]
                                      ? elRefs[18].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cargo-pockets-right"
                                  style={{ left: "40%", top: "52%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[18].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[18].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[14]
                                      ? elRefs[14].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="leasing-collar"
                                  style={{ left: "45%", top: "38%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[14].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[14].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[7]
                                      ? elRefs[7].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cuff-inside"
                                  style={{ left: "58%", top: "92%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[7].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[7].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              className="product-display__image product-display__image--small js-product-display__image "
                              data-index={3}
                            >
                              <img
                                data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_B.png"
                                alt=""
                                className=" ls-is-cached lazyloaded"
                                src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_B.png"
                              />
                              <div className="product-display__indicators">
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[11]
                                      ? elRefs[11].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="brace-system"
                                  style={{ left: "51%", top: "15%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[11].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[11].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[21]
                                      ? elRefs[21].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="lumbar-pad"
                                  style={{ left: "50%", top: "33%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[21].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[21].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[6]
                                      ? elRefs[6].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="cuff-backside"
                                  style={{ left: "52%", top: "98%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[6].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[6].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[15]
                                      ? elRefs[15].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="name-tag"
                                  style={{ left: "38%", top: "54%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[15].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[15].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[16]
                                      ? elRefs[16].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="laundering-protocol-label"
                                  style={{ left: "42%", top: "40%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[16].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[16].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[17]
                                      ? elRefs[17].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="rfid"
                                  style={{ left: "50%", top: "43%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[17].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[17].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[12]
                                      ? elRefs[12].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="inspection-openings"
                                  style={{ left: "50%", top: "38%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[12].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[12].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                                <div
                                  className={
                                    "product-display__indicator js-product-display__indicator " +
                                    (elRefs[8]
                                      ? elRefs[8].isactive
                                        ? "active"
                                        : ""
                                      : "")
                                  }
                                  data-id="step-in-gusset"
                                  style={{ left: "38%", top: "90%" }}
                                  onClick={() => {
                                    setElRefs((dd) => {
                                      const nextShapes = elRefs.map((shape) => {
                                        if (shape.id === elRefs[8].id) {
                                          // Return a new circle 50px below
                                          return {
                                            ...shape,
                                            isactive: true,
                                          };
                                        } else {
                                          // Return a new circle 50px below
                                          return { ...shape, isactive: false };
                                        }
                                      });
                                      return nextShapes;
                                    });
                                    elRefs[8].refitem.current.scrollIntoView({
                                      block: "center",
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="product-display__navigation product-display__navigation--4">
                        <div className="product-display__curve product-display__curve--4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={289}
                            height={40}
                            viewBox="0 0 289 40"
                          >
                            <path
                              fill="none"
                              stroke="#C5C6C6"
                              d="M5.165 32.202C32.78 35.254 64.825 37 99 37c103.83 0 188-16.118 188-36"
                            />
                          </svg>
                          <div
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 0 ? "active" : "")
                            }
                            onClick={() => setindex(0)}
                            data-index={0}
                          />
                          <div
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 1 ? "active" : "")
                            }
                            onClick={() => setindex(1)}
                            data-index={1}
                          />
                          <div
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 2 ? "active" : "")
                            }
                            onClick={() => setindex(2)}
                            data-index={2}
                          />
                          <div
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 3 ? "active" : "")
                            }
                            onClick={() => setindex(3)}
                            data-index={3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-details__right">
                {/* uptitle */}
                <div className="product-details__uptitle">
                  <div className="uptitle ">
                    <div className="icon uptitle__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-texport-logo" />
                      </svg>
                    </div>{" "}
                    Details{" "}
                  </div>
                </div>
                {/* tabs */}
                {/* accordion */}
                <div className="product-details__accordion">
                  <div
                    className="product-details__accordion__group js-product-details__content active"
                    data-tab-id="fire-twin_drag_60a4e68d6b60a"
                  >
                    {listref.map((item) => item.ht1)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Details;
