import part1 from "./sergeferrrari.png";
import part2 from "./BAESYSTEMS.png";
import part3 from "./survitec-logo.svg";
function Partners() {
  return (
    <>
      <div
        className="module module--headline module--space-top-big module--space-bottom-tiny "
        data-name="headline"
      >
        <div className="module__content">
          <div className="headline__outer container--medium">
            <div className="headline container container--small">
              <div className="headline__uptitle">
                <div className="uptitle ">
                  <div className="icon uptitle__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-texport-logo" />
                    </svg>
                  </div>{" "}
                  Partner{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module module--text module--space-big " data-name="text">
        <div class="module__content">
          <div class="headline container container--small">
            <div class="headline__text-heavy text text--heavy ">
              You don’t create great things on your own. To have our fingers on
              the pulse of time, we at Sondos Advanced<sup>®</sup> have built up
              a top-class partner network.{" "}
            </div>

            <div class="headline__text text text  ">
              <p>
                Safety has the highest priority in the development and
                manufacture of protective clothing – after all, it is about
                protecting human life. The further development and performance
                enhancement of technical fabrics, fibers and fabrics is
                therefore an indispensable process. Our partner network has
                grown steadily over the years and now consists of world-leading
                know-how carriers in the respective field. These license
                partnerships represent a further building block for our high
                quality.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="module module--partner-grid module--space-big "
        data-name="partner-grid"
      >
        <div className="module__content">
          <div className="partner-grid container">
            <div className="partner-grid__inner">
              <a className="partner-grid__card js-ga4-track">
                <div className="partner-grid__card__left">
                  <img decoding="async" src={part1} alt="" />
                </div>
                <div className="partner-grid__card__right">
                  <div className="partner-grid__card__title">Serge Ferrari</div>
                  <div className="partner-grid__card__text">
                    Exclusive agency for products.
                  </div>
                  <button className="cta                   ">
                    <div className="icon cta__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-circle-arrow" />
                      </svg>
                    </div>{" "}
                    <span> To the partner page</span>
                  </button>
                </div>
              </a>
              <a className="partner-grid__card js-ga4-track">
                <div className="partner-grid__card__left">
                  <img decoding="async" src={part2} alt="" />
                </div>
                <div className="partner-grid__card__right">
                  <div className="partner-grid__card__title">BAE SYSTEMS</div>
                  <div className="partner-grid__card__text">
                    Industrial partnership.
                  </div>
                  <button className="cta                   ">
                    <div className="icon cta__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-circle-arrow" />
                      </svg>
                    </div>{" "}
                    <span> To the partner page</span>
                  </button>
                </div>
              </a>
              <a className="partner-grid__card js-ga4-track">
                <div className="partner-grid__card__left">
                  <img decoding="async" src={part3} alt="" />
                </div>
                <div className="partner-grid__card__right">
                  <div className="partner-grid__card__title">British Survitec</div>
                  <div className="partner-grid__card__text">
                   Localization Partnership.
                  </div>
                  <button className="cta                   ">
                    <div className="icon cta__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-circle-arrow" />
                      </svg>
                    </div>{" "}
                    <span> To the partner page</span>
                  </button>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Partners;
