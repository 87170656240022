function Headerc() {
  return (
    <div
      className="module module--headline module--space-top-big module--space-bottom-tiny "
      data-name="headline"
    >
      <div className="module__content">
        <div className="headline__outer container--medium">
          <div className="headline container container--small">
            <div className="headline__uptitle">
              <div className="uptitle ">
                <div className="icon uptitle__icon">
                  <svg>
                    <use xlinkHref="/symbols.svg#icon-texport-logo" />
                  </svg>
                </div>{" "}
                Contact person{" "}
              </div>
            </div>
            <div className=" headline__title h1 ">
              Contact Sondos Advanced<sup>®</sup>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Headerc;
