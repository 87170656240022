import { useState } from "react";
import smoke from "../../../img/smoke.png";
import Product from "./Product";
function ProductSlider() {
  const [selectslide, setselectslide] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50 

  const onTouchStart = (e) => {
    setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX)
  }
  
  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX)
  
  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return
    const distance = touchStart - touchEnd
    const isLeftSwipe = distance > minSwipeDistance
    const isRightSwipe = distance < -minSwipeDistance
    if (isLeftSwipe) {
      setselectslide((selectslide) =>
        selectslide + 1 < slide.length ? selectslide + 1 : selectslide
      )
    } 
    if (isRightSwipe) {
      setselectslide((selectslide) =>
        selectslide < 1 ? slide.length - 1 : selectslide - 1
      )
    }
    if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
    // add your conditional logic here
  }

  const slide = [
    {
      id: 0,
      name: "Fire Inforcer Jacket",
      img: [
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Jacke_dblau_FL.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Jacke_dblau_F.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Jacke_dblau_FR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Jacke_dblau_BR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Jacke_dblau_B.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Jacke_dblau_BL.png",
      ],
    },
    {
      id: 1,
      name: "Fire Inforcer Trousers",
      img: [
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Hose_dblau_FL.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Hose_dblau_F.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Hose_dblau_FR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Hose_dblau_BR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Hose_dblau_B.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Fire_Inforcer_Hose_dblau_BL.png",
      ],
    },
    {
      id: 2,
      name: "Leader FR Motion Blouson",
      img: [
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Jacke_dblau_FL.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Jacke_dblau_F.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Jacke_dblau_FR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Jacke_dblau_BR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Jacke_dblau_B.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Jacke_dblau_BL.png",
      ],
    },
    {
      id: 3,
      name: "Leader FR Motion Trousers",
      img: [
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Hose_dblau_FL.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Hose_dblau_F.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Hose_dblau_FR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Hose_dblau_BR.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Hose_dblau_B.png",
        "https://www.texport.at/en/wp-content/uploads/sites/4/2024/05/Leader_FR_Motion_Hose_dblau_BL.png",
      ],
    },
  ];

  const widthprod = (window.outerWidth - 24) * 0.6 - 14;
  return (
    <div className="module module--product-slider  " data-name="product-slider">
      <div className="module__content">
        <div className="product-slider">
          <div className="container container--small">
            <div className="product-slider__uptitle product-slider__uptitle--mobile">
              <div className="uptitle ">
                <div className="icon uptitle__icon">
                  <svg>
                    <use xlinkHref="/symbols.svg#icon-texport-logo" />
                  </svg>
                </div>{" "}
                NEW PRODUCTS – OUT NOW!{" "}
              </div>
            </div>
            <div className="product-slider__slider swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-autoheight">
              <div
                className="swiper-wrapper"
                onTouchStart={(touchStartEvent) =>
                  onTouchStart(touchStartEvent)
                }
                onTouchMove={(touchMoveEvent) =>
                  onTouchMove(touchMoveEvent)
                }
                onTouchEnd={() => onTouchEnd()}
                style={{
                  height: 497,
                  transform:
                    "translate3d(" +
                    -widthprod * selectslide +
                    "px, 0px, 0px) ",
                }}
              >
                {slide.map((item) => {
                  return (
                    <Product
                      dataprod={item}
                      selectslide={selectslide}
                    ></Product>
                  );
                })}
              </div>
              <div className="slider-navigation slider-navigation--light">
                <div
                  className="slider-navigation__arrow slider-prev icon swiper-button-disabled"
                  onClick={() =>
                    setselectslide((selectslide) =>
                      selectslide < 1 ? slide.length - 1 : selectslide - 1
                    )
                  }
                  role="button"
                  aria-label="Previous slide"
                  aria-disabled="true"
                >
                  <svg>
                    <use xlinkHref="/symbols.svg#icon-chevron-left" />
                  </svg>
                </div>
                <div className="slider-navigation__pagination slider-pagination swiper-pagination-fraction">
                  <span className="swiper-pagination-current">
                    {selectslide + 1}
                  </span>{" "}
                  /{" "}
                  <span className="swiper-pagination-total">
                    {slide.length}
                  </span>
                </div>
                <div
                  onClick={() =>
                    setselectslide((selectslide) =>
                      selectslide + 1 < slide.length ? selectslide + 1 : 0
                    )
                  }
                  className="slider-navigation__arrow slider-next icon"
                  tabIndex={0}
                  role="button"
                  aria-label="Next slide"
                  aria-disabled="false"
                >
                  <svg>
                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                  </svg>
                </div>
              </div>
              <span
                className="swiper-notification"
                aria-live="assertive"
                aria-atomic="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductSlider;
