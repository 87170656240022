import { useRef, useState } from "react";

function Highlights({ referense }) {
  const refTripF = useRef(null);
  const refwb = useRef(null);
  const refegro = useRef(null);
  const [istripf, setistripf] = useState(false);
  const [istwb, setiwp] = useState(false);
  const [isefro, setisefro] = useState(false);
  const [indexselect, setindexselect] = useState(0);
  return (
    <div
      className="module module--product-details module--space-big "
      data-name="product-details"
      data-module-id="highlights"
      ref={referense}
    >
      <div className="module__content">
        {/* <div class="product-details product-details--large" > */}
        {/* load related product script of currently active element */}
        <div className="product-details product-details--large">
          {/* <div class="product-details__main-item js-product-details__product " id="66cd8767b1035" > */}
          <div
            className="product-details__main-item js-product-details__product "
            id="66cd8767b1035"
          >
            <div className="product-details__container container">
              {/* product */}
              <div className="product-details__left">
                <div className="product-details__product">
                  {/* <div class="product-details__product__group js-product-details__content active" data-tab-id=""> */}
                  <div
                    className="product-details__product__group js-product-details__content active"
                    data-tab-id=""
                  >
                    <div className="product-display product-display--nosmoke ">
                      <div
                        className="product-display__main js-product-display__parallax"
                        style={{
                          transform:
                            "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
                          transformStyle: "preserve-3d",
                          backfaceVisibility: "hidden",
                          pointerEvents: "none",
                        }}
                      >
                        <div
                          className="product-display__product-images"
                          data-depth={0}
                          style={{
                            transform: "translate3d(0px, 0px, 0px)",
                            transformStyle: "preserve-3d",
                            backfaceVisibility: "hidden",
                            position: "relative",
                            display: "block",
                            left: 0,
                            top: 0,
                          }}
                        >
                          <div
                            className={
                              "product-display__image product-display__image--small js-product-display__image " +
                              (indexselect === 0 ? "active" : "")
                            }
                            data-index={0}
                          >
                            <img
                              className=" ls-is-cached lazyloaded"
                              src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FL.png"
                            />
                            <div className="product-display__indicators">
                              <div
                                className={
                                  "product-display__indicator js-product-display__indicator" +
                                  (isefro ? " active" : "")
                                }
                                onClick={() => {
                                  setistripf(false);
                                  setiwp(false);
                                  setisefro(true);
                                  refegro.current.scrollIntoView({
                                    block: "center",
                                    inline: "center",
                                    behavior: "auto",
                                  });
                                }}
                                data-id="ergopad"
                                style={{ left: "60%", top: "70%" }}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              "product-display__image product-display__image--small js-product-display__image " +
                              (indexselect === 1 ? "active" : "")
                            }
                            data-index={1}
                          >
                            <img
                              className=" ls-is-cached lazyloaded"
                              src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_F.png"
                            />
                            <div className="product-display__indicators">
                              <div
                                className={
                                  "product-display__indicator js-product-display__indicator" +
                                  (istwb ? " active" : "")
                                }
                                onClick={() => {
                                  setistripf(false);
                                  setiwp(true);
                                  setisefro(false);
                                  refwb.current.scrollIntoView({
                                    block: "center",
                                    inline: "center",
                                    behavior: "auto",
                                  });
                                }}
                                style={{ left: "40%", top: "95%" }}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              "product-display__image product-display__image--small js-product-display__image " +
                              (indexselect === 2 ? "active" : "")
                            }
                            data-index={2}
                          >
                            <img
                              alt=""
                              className=" ls-is-cached lazyloaded"
                              src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FR.png"
                            />
                            <div className="product-display__indicators">
                              <div
                                className={
                                  "product-display__indicator js-product-display__indicator" +
                                  (istripf ? " active" : "")
                                }
                                onClick={() => {
                                  setistripf(true);
                                  setiwp(false);
                                  setisefro(false);
                                  refTripF.current.scrollIntoView({
                                    block: "center",
                                    inline: "center",
                                    behavior: "auto",
                                  });
                                }}
                                style={{ left: "45%", top: "85%" }}
                              />
                            </div>
                          </div>
                          <div
                            className={
                              "product-display__image product-display__image--small js-product-display__image " +
                              (indexselect === 3 ? "active" : "")
                            }
                            data-index={3}
                          >
                            <img
                              className=" ls-is-cached lazyloaded"
                              src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_B.png"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="product-display__navigation product-display__navigation--4">
                        <div className="product-display__curve product-display__curve--4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={289}
                            height={40}
                            viewBox="0 0 289 40"
                          >
                            <path
                              fill="none"
                              stroke="#C5C6C6"
                              d="M5.165 32.202C32.78 35.254 64.825 37 99 37c103.83 0 188-16.118 188-36"
                            />
                          </svg>
                          <div
                            onClick={() => {
                              setindexselect(0);
                            }}
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 0 ? "active" : "")
                            }
                            data-index={0}
                          />
                          <div
                            onClick={() => {
                              setindexselect(1);
                            }}
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 1 ? "active" : "")
                            }
                            data-index={1}
                          />
                          <div
                            onClick={() => {
                              setindexselect(2);
                            }}
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 2 ? "active" : "")
                            }
                            data-index={2}
                          />
                          <div
                            onClick={() => {
                              setindexselect(3);
                            }}
                            className={
                              "product-display__curve__dot js-product-display__dot " +
                              (indexselect === 3 ? "active" : "")
                            }
                            data-index={3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="product-details__right">
                {/* uptitle */}
                <div className="product-details__uptitle">
                  <div className="uptitle ">
                    <div className="icon uptitle__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-texport-logo" />
                      </svg>
                    </div>{" "}
                    Highlights{" "}
                  </div>
                </div>
                {/* tabs */}
                {/* accordion */}
                <div className="product-details__accordion">
                  {/* <div class="product-details__accordion__group js-product-details__content active" data-tab-id="" data-component='{"component": "accordion"}' data-accordion-settings='{"closeOthers": true, "toggleListenerEvent": "indicator-click-66cd8767b109f", "toggleDispatchEvent": "accordion-toggle-66cd8767b109f"}'> */}
                  {/* <div class="product-details__accordion__group js-product-details__content active" data-tab-id="" data-component='{"component": "accordion"}' data-accordion-settings='{"closeOthers": true, "toggleListenerEvent": "indicator-click-66cd8767b109f", "toggleDispatchEvent": "accordion-toggle-66cd8767b109f", "alwaysScrollToItem": true}'> */}
                  <div className="product-details__accordion__group js-product-details__content active">
                    <div
                      className={
                        "product-details__item" + (istripf ? " is-open" : "")
                      }
                      ref={refTripF}
                    >
                      <div
                        className="product-details__item__head js-accordion__head"
                        onClick={() => {
                          setistripf((istripf) => !istripf);
                          setisefro(false);
                          setiwp(false)
                          setindexselect(2);
                        }}
                        data-indicator-id="texport-triple-fabric"
                      >
                        <div className="product-details__item__title h4">
                          Triple Fabric®
                        </div>

                        <div className="product-details__item__plus" />
                      </div>
                      <div
                        className="product-details__item__body js-accordion__body"
                        style={{ display: istripf ? "block" : "none" }}
                      >
                        <div className="product-details__item__content">
                          <img
                            src="https://www.texport.at/en/wp-content/uploads/sites/4/2021/02/texport_usp_trible_fabric.png"
                            className="product-details__item__image ls-is-cached lazyloaded"
                          />
                          <div className="product-details__item__text text ">
                            <p>
                              The Triple Fabric<sup>®</sup> is a textile
                              reflective stripe that is made from fabric and
                              therefore is extremely breathable and more
                              durable. The reflective, silverstripe material
                              composition, patented by 3M*, adheres very well to
                              the structure of the soft fabric. In addition, the
                              TEXPORT<sup>®</sup> Triple Fabric
                              <sup>®</sup> is abraded less severely through wear
                              and washing.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "product-details__item" + (istwb ? " is-open" : "")
                      }
                      ref={refwb}
                    >
                      <div
                        className="product-details__item__head js-accordion__head"
                        onClick={() => {
                          setistripf(false);
                          setisefro(false);
                          setiwp((istwb) => !istwb)
                          setindexselect(1);
                        }}
                        data-indicator-id="wicking-barrier"
                      >
                        <div className="product-details__item__title h4">
                          Wicking Barrier
                        </div>

                        <div className="product-details__item__plus" />
                      </div>
                      <div
                        className="product-details__item__body js-accordion__body"
                        style={{ display: istwb ? "block" : "none" }}
                      >
                        <div className="product-details__item__content">
                          <img
                            src="https://www.texport.at/en/wp-content/uploads/sites/4/2021/02/texport_usp_saugsperre.png"
                            alt="Wicking Barrier"
                            className="product-details__item__image ls-is-cached lazyloaded"
                          />
                          <div className="product-details__item__text text ">
                            <p>
                              The wicking barrier is permanently waterproof,
                              breathable and wash-resistant. That means even
                              after many uses and washes, the clothing’s lining
                              remains completely dry thanks to the GORE-TEX
                              barrier, as no more moisture can enter through the
                              seams.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={
                        "product-details__item" + (isefro ? " is-open" : "")
                      }
                      ref={refegro}
                    >
                      <div
                        className="product-details__item__head js-accordion__head"
                        onClick={() => {
                          setistripf(false);
                          setisefro((isefro) => !isefro);
                          setiwp(false)
                          setindexselect(0);
                        }}
                        data-indicator-id="ergopad"
                      >
                        <div className="product-details__item__title h4">
                          Ergopad®
                        </div>

                        <div className="product-details__item__plus" />
                      </div>
                      <div
                        className="product-details__item__body js-accordion__body"
                        style={{ display: isefro ? "block" : "none" }}
                      >
                        <div className="product-details__item__content">
                          <img
                            src="https://www.texport.at/en/wp-content/uploads/sites/4/2022/10/TEXPORT_Ergopad.png"
                            alt="Ergopad®"
                            className="product-details__item__image ls-is-cached lazyloaded"
                          />
                          <div className="product-details__item__text text ">
                            <p>
                              The Ergopad<sup>®</sup> is a cushioned,
                              ergonomically preformed knee pad that fits the
                              knee in every place and avoids creating pressure
                              marks. It is flame-resistant and does not become
                              saturated with water, in contrast to the aramid
                              non-woven padding, so that the trousers dry much
                              more quickly after washing.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Highlights;
