function Specifications({ referense, selectedfabric }) {
  return (
    <div
      className="module module--accordion-text module--space-big "
      data-name="accordion-text"
      data-module-id="specifications"
      ref={referense}
    >
      <div className="module__content">
        <div className="accordion product-specifications">
          <div
            className="accordion__container container "
            data-accordion-settings='{"closeOthers": true,"alwaysScrollToItem": true }'
          >
            <div className="accordion__inner">
              <div className="accordion__head js-accordion__head is-open">
                <h3 className="h3">Specifications</h3>
                <div className="accordion__head__right">
                  <div className="accordion__head__expand-button" />
                </div>
              </div>
              {selectedfabric === "0" ? (
                <div
                  className="accordion__body container--smaller js-accordion__body"
                  style={{ display: "block" }}
                >
                  <div>
                    {/* <div class="accordion__body__columns js-product-specifications__tab active" data-tab-id="fire-twin_drag_60a4e68d6b60a"> */}
                    <div
                      className="accordion__body__columns js-product-specifications__tab active"
                      data-tab-id="fire-twin_drag_60a4e68d6b60a"
                    >
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Certified according to:{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="certificate"
                        >
                          EN 1149-5:2018
                          <br /> EN 469:2020 X2 Y2 Z2
                          <br /> EN ISO 11612:2015 A1 B1 C1{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Composition{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="structure"
                        >
                          X-Treme<sup>®</sup>{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Out layer{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="fabric"
                        >
                          Nomex<sup>®</sup> NXT{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Color{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="colour"
                        >
                          Dark Blue{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Size{" "}
                        </div>
                        <div className="text js-product-specifications__field">
                          XS – 4XL{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="accordion__body container--smaller js-accordion__body"
                  style={{ display: "block" }}
                >
                  <div>
                    {/* <div class="accordion__body__columns js-product-specifications__tab active" data-tab-id="fire-twin_drag_60a4e68d6b60a"> */}
                    <div
                      className="accordion__body__columns js-product-specifications__tab active"
                      data-tab-id="fire-twin_drag_60a4e68d6b60a"
                    >
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Certified according to:{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="certificate"
                        >
                          EN 469:2020 X2 Y2 Z2
                          <br /> EN 1149-5:2018
                          <br /> EN ISO 11612:2015 A1 B1 C1{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Composition{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="structure"
                        >
                          X-Treme<sup>®</sup>{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Out layer{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="fabric"
                        >
                          PBI<sup>®</sup> NEO<sup>®</sup>{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Color{" "}
                        </div>
                        <div
                          className="text js-product-specifications__field"
                          data-field="colour"
                        >
                          Gold{" "}
                        </div>
                      </div>
                      <div className="accordion__body__columns__item">
                        <div className="text text--extra-bold text--white">
                          Size{" "}
                        </div>
                        <div className="text js-product-specifications__field">
                          XS – 4XL{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Specifications;
