import { useState } from "react";
import matnxt from "../../../img/Firesuits/matnxt.png";
import matpbineo from "../../../img/Firesuits/matpbineo.png";
function Accordionimage({ referense }) {
  const [indexlo, setindexlo] = useState(0);

  return (
    <div
      className="module module--accordion-image module--space-big "
      data-name="accordion-image"
      ref={referense}
    >
      <div className="module__content">
        <div
          className="accordion accordion-image "
          data-image-display-settings='{"indicatorListenerEvent" : "accordion-toggle-66cd8767af017" }'
        >
          <div className="accordion-image__container container">
            <div className="accordion-image__content">
              <div className="accordion-image__uptitle">
                <div className="uptitle ">
                  <div className="icon uptitle__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-texport-logo" />
                    </svg>
                  </div>{" "}
                  Outer Materials{" "}
                </div>
              </div>
              <h2 className="accordion-image__title h3">
                Innovative outer materials
              </h2>
            </div>
            {/* <div class="accordion-image__image js-accordion-image-swiper__image " id="pbi-neo"> */}
            <div
              className={
                "accordion-image__image js-accordion-image-swiper__image " +
                (indexlo != 0 ? "is-hidden" : "")
              }
              id="pbi-neo"
            >
              <img
                alt="PBI® NEO®"
                className="desktop lazyloaded"
                src={matpbineo}
              />
            </div>
            <div
              className={
                "accordion-image__image js-accordion-image-swiper__image " +
                (indexlo != 1 ? "is-hidden" : "")
              }
              id="nomex-nxt"
            >
              <img
                alt="Nomex® NXT"
                className="desktop lazyloaded"
                src={matnxt}
              />
            </div>
            <div className="accordion-image__accordions">
              <div className="accordion-image__item">
                <div
                  className={
                    "accordion-image__item__head js-accordion__head " +
                    (indexlo === 0 ? "is-open" : "")
                  }
                  onClick={()=>setindexlo(0)}
                  data-indicator-id="pbi-neo"
                >
                  <div className="h6">PBI® NEO®</div>
                  <div className="accordion__head__expand-button" />
                </div>
                <div
                  className="accordion-image__item__body js-accordion__body"
                  style={{ display: indexlo === 0 ? "block" : "none" }}
                >
                  <div className="text">
                    High mechanical strength, combined with the well-known,
                    established benefits of PBI<sup>®</sup>, makes PBI
                    <sup>®</sup> NEO<sup>®</sup> an excellent addition to the
                    PBI<sup>®</sup> outer layers. The high tensile strength and
                    tear resistance speak for themselves. Thanks to the very
                    good wash resistance, the PBI<sup>®</sup> NEO<sup>®</sup>{" "}
                    maintains its smart appearance even after many washes. PBI
                    <sup>®</sup> fabrics do not melt or shrink and do not crack
                    after exposure to heat and flames. As a result of its
                    special PowerShell finishing, re-waterproofing is not
                    required until after 30 washing cycles.
                  </div>
                  <div className="accordion-image__colors">
                    <div className="accordion-image__colors__label text text--small">
                      Available in:
                    </div>
                    <div className="accordion-image__color">
                      <div
                        className="accordion-image__color__circle"
                        style={{ background: "#a48111" }}
                      />
                      <div className="accordion-image__color__name text text--small">
                        Gold
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-image__item">
                {/* <div class="accordion-image__item__head js-accordion__head " data-indicator-id="nomex-nxt"> */}
                <div
                  className={
                    "accordion-image__item__head js-accordion__head " +
                    (indexlo === 1 ? "is-open" : "")
                  }
                  onClick={()=>setindexlo(1)}

                  data-indicator-id="nomex-nxt"
                >
                  <div className="h6">Nomex® NXT</div>
                  <div className="accordion__head__expand-button" />
                </div>
                {/* <div class="accordion-image__item__body js-accordion__body" > */}
                <div
                  className="accordion-image__item__body js-accordion__body"
                  style={{ display: indexlo === 1 ? "block" : "none" }}
                >
                  <div className="text">
                    With the new DuPontTM Nomex<sup>®</sup> NXT, the quality of
                    the reputable Nomex<sup>®</sup> Outershell Tough has once
                    again been significantly improved. The new technical design
                    guarantees at once both maximum comfort to wear and high
                    protection.
                  </div>
                  <div className="accordion-image__colors">
                    <div className="accordion-image__colors__label text text--small">
                      Available in:
                    </div>
                    <div className="accordion-image__color">
                      <div
                        className="accordion-image__color__circle"
                        style={{ background: "#003068" }}
                      />
                      <div className="accordion-image__color__name text text--small">
                        Dark Blue
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Accordionimage;
