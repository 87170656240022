import { useState } from "react";
import img from "../../../img/smoke.png";
function Info({ referense,selectedfabric, setselectedfabric }) {
  const [indexselect, setindex] = useState(0);
  return (
    <div
      ref={referense}
      class="module module--product-header module--space-top-text module--space-bottom-small "
      data-name="product-header"
      data-module-id="infos"
    >
      <div class="module__content">
        <div class="product-header">
          <div class="product-header__container container container--medium">
            <div className="product-header__left">
              <div className="product-header__left__inner">
                {/* if active type is = to active type index set that type as active */}
                <div
                  className="product-header__content__tab js-product-header__content-tab active"
                  data-tab-id="fire-twin_drag_60a4e68d6b60a"
                >
                  {/* check if current index matches to active fabric index if yes set state to active */}
                  <div
                    className={
                      "product-header__content__tab js-product-header__content-fabric " +
                      (selectedfabric === "1" ? "active" : "")
                    }
                    data-fabric="pbi-neo"
                  >
                    {/* check if current colour index matches active color index if yes set colour active */}
                    <div
                      className="product-header__content__tab js-product-header__content-colour active"
                      data-colour="Gold"
                      data-size="XS - 4XL"
                    >
                      <div className="product-header__display">
                        <div
                          className="product-display product-display--nosmoke product-display--smallsmoke"
                          data-product-display-settings="{ }"
                        >
                          <div
                            className="product-display__main js-product-display__parallax"
                            style={{
                              transform:
                                "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
                              transformStyle: "preserve-3d",
                              backfaceVisibility: "hidden",
                              pointerEvents: "none",
                            }}
                          >
                            <div
                              className="product-display__product-images"
                              data-depth={0}
                              style={{
                                transform: "translate3d(0px, 0px, 0px)",
                                transformStyle: "preserve-3d",
                                backfaceVisibility: "hidden",
                                position: "relative",
                                display: "block",
                                left: 0,
                                top: 0,
                              }}
                            >
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 0 ? "active" : "")
                                }
                                data-index={0}
                              >
                                <img
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_FL.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 0
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                />
                              </div>
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 1 ? "active" : "")
                                }
                                data-index={1}
                              >
                                <img
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_F.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 1
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                />
                              </div>
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 2 ? "active" : "")
                                }
                                data-index={2}
                              >
                                <img
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_FR.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 2
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                />
                              </div>
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 3 ? "active" : "")
                                }
                                data-index={3}
                              >
                                <img
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_B.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 3
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="product-display__navigation product-display__navigation--4">
                            <div className="product-display__curve product-display__curve--4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={289}
                                height={40}
                                viewBox="0 0 289 40"
                              >
                                <path
                                  fill="none"
                                  stroke="#C5C6C6"
                                  d="M5.165 32.202C32.78 35.254 64.825 37 99 37c103.83 0 188-16.118 188-36"
                                />
                              </svg>
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 0 ? "active" : "")
                                }
                                onClick={() => setindex(0)}
                                data-index={0}
                              />
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 1 ? "active" : "")
                                }
                                onClick={() => setindex(1)}
                                data-index={1}
                              />
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 2 ? "active" : "")
                                }
                                onClick={() => setindex(2)}
                                data-index={2}
                              />
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 3 ? "active" : "")
                                }
                                onClick={() => setindex(3)}
                                data-index={3}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* show correct structure */}
                      <a
                        href=""
                        target=""
                        className="product-header__related-product related-product js-product-header__related-product "
                        data-structure=""
                      >
                        <div className="related-product__image">
                          <img data-src="" alt=" " className="lazyload" />
                        </div>
                        <div className="related-product__title text text--small text--bold"></div>
                      </a>
                    </div>
                  </div>
                  {/* check if current index matches to active fabric index if yes set state to active */}
                  <div
                    className={
                      "product-header__content__tab js-product-header__content-fabric " +
                      (selectedfabric === "0" ? "active" : "")
                    }
                    data-fabric="nomex-nxt"
                  >
                    {/* check if current colour index matches active color index if yes set colour active */}
                    <div
                      className="product-header__content__tab js-product-header__content-colour active"
                      data-colour="Dark Blue"
                      data-size="XS - 4XL"
                    >
                      <div className="product-header__display">
                        <div
                          className="product-display product-display--nosmoke product-display--smallsmoke"
                          data-product-display-settings="{ }"
                        >
                          <div
                            className="product-display__main js-product-display__parallax"
                            style={{
                              transform:
                                "translate3d(0px, 0px, 0px) rotate(0.0001deg)",
                              transformStyle: "preserve-3d",
                              backfaceVisibility: "hidden",
                              pointerEvents: "none",
                            }}
                          >
                            <div
                              className="product-display__product-images"
                              data-depth={0}
                              style={{
                                transform: "translate3d(0px, 0px, 0px)",
                                transformStyle: "preserve-3d",
                                backfaceVisibility: "hidden",
                                position: "relative",
                                display: "block",
                                left: 0,
                                top: 0,
                              }}
                            >
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 0 ? "active" : "")
                                }
                                data-index={0}
                              >
                                <img
                                  data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FL.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 0
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FL.png"
                                />
                              </div>
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 1 ? "active" : "")
                                }
                                data-index={1}
                              >
                                <img
                                  data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_F.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 1
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_F.png"
                                />
                              </div>
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 2 ? "active" : "")
                                }
                                data-index={2}
                              >
                                <img
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FR.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 2
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                />
                              </div>
                              <div
                                className={
                                  "product-display__image product-display__image--small js-product-display__image " +
                                  (indexselect === 3 ? "active" : "")
                                }
                                data-index={3}
                              >
                                <img
                                  src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_B.png"
                                  alt="Fire Twin Trousers"
                                  className={
                                    indexselect === 3
                                      ? "lazyloaded"
                                      : "lazyload"
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="product-display__navigation product-display__navigation--4">
                            <div className="product-display__curve product-display__curve--4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={289}
                                height={40}
                                viewBox="0 0 289 40"
                              >
                                <path
                                  fill="none"
                                  stroke="#C5C6C6"
                                  d="M5.165 32.202C32.78 35.254 64.825 37 99 37c103.83 0 188-16.118 188-36"
                                />
                              </svg>
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 0 ? "active" : "")
                                }
                                onClick={() => setindex(0)}
                                data-index={0}
                              />
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 1 ? "active" : "")
                                }
                                onClick={() => setindex(1)}
                                data-index={1}
                              />
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 2 ? "active" : "")
                                }
                                onClick={() => setindex(2)}
                                data-index={2}
                              />
                              <div
                                className={
                                  "product-display__curve__dot js-product-display__dot " +
                                  (indexselect === 3 ? "active" : "")
                                }
                                onClick={() => setindex(3)}
                                data-index={3}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* show correct structure */}
                      <a
                        target=""
                        className="product-header__related-product related-product js-product-header__related-product active"
                        data-structure="x-treme"
                      >
                        <div className="related-product__image">
                          <img
                            data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Jacke_NOMEX_NXT_dblau_ZA16_FL.png"
                            alt=" Related Jacket"
                            className=" ls-is-cached lazyloaded"
                            src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Jacke_NOMEX_NXT_dblau_ZA16_FL.png"
                          />
                        </div>
                        <div className="related-product__title text text--small text--bold">
                          Related Jacket{" "}
                        </div>
                      </a>
                      {/* show correct structure */}
                      <a
                        target=""
                        className="product-header__related-product related-product js-product-header__related-product "
                        data-structure="x-treme-light"
                      >
                        <div className="related-product__image">
                          <img
                            data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Jacke_NOMEX_NXT_dblau_ZA16_FL.png"
                            alt=" Related Jacket"
                            className="lazyload"
                          />
                        </div>
                        <div className="related-product__title text text--small text--bold">
                          Related Jacket{" "}
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
                <div
                  className="product-header__smoke product-display__smoke"
                  data-depth="0.08"
                >
                  <img className=" lazyloaded" alt="Smoke" src={img} />
                </div>
                <div className="product-header__left__spotlight" />
              </div>
            </div>
            <div className="product-header__right">
              <div className="product-header__right__top">
                <h1 className="product-header__title h2">REMAL SUIT TROUSER</h1>
              </div>
              {/* tabs */}
              {/* show active tab, hide inactives */}
              <div className="product-header__tabs ">
                {/* if active type is = current index set this tab as active */}
                <div
                  className="product-header__tabs__item js-product-header__tablink h6 active"
                  data-tab-id="fire-twin_drag_60a4e68d6b60a"
                >
                  Drag
                </div>
              </div>
              <div className="product-header__content">
                {/* content tab */}
                {/* get content based on active tab */}
                <div
                  className="product-header__content__tab js-product-header__content-tab active"
                  data-tab-id="fire-twin_drag_60a4e68d6b60a"
                >
                  {/* description */}
                  <div className="product-header__description">
                    <div className="text">
                      The Remal Suit from Sondos Advanced <sup>®</sup> is a suit
                      for any deployment situation. This firefighting suit was
                      originally developed for Civil Devense S.A
                    </div>
                  </div>
                  {/* Check if currently active structure and fabric value are empty */}
                  <div className="product-header__dropdowns ">
                    {/* check if active structure value is empty */}
                    <div className="product-header__dropdowns__item ">
                      <div className="input-wrapper  ">
                        {/* dropdown / select */}
                        <div className="input-dropdown">
                          <select
                            name="structure"
                            id="structure"
                            className="input js-product-header__structure"
                          >
                            <option
                              value="x-treme"
                              selected
                              data-fabrics='[{"mapping_name":"PBI\u00ae NEO\u00ae","value":"pbi-neo","active":false,"available_colours":["Gold"]},{"mapping_name":"Nomex\u00ae NXT","value":"nomex-nxt","active":true,"available_colours":["Dark Blue"]}]'
                              data-code="ZA"
                              data-size="XS - 4XL"
                            >
                              X-Treme®
                            </option>
                            <option
                              value="x-treme-light"
                              data-fabrics='[{"mapping_name":"Nomex\u00ae NXT","value":"nomex-nxt","active":false,"available_colours":["Dark Blue"]}]'
                              data-code="YA"
                              data-size="XS - 4XL"
                            >
                              X-Treme® light
                            </option>
                          </select>
                          <div className="icon input-dropdown__icon">
                            <svg>
                              <use xlinkHref="/symbols.svg#icon-chevron-down" />
                            </svg>
                          </div>{" "}
                        </div>
                        {/* textarea */}
                        <label htmlFor="structure" className="input-label">
                          {/* checkbox icon  */}
                          Composition{" "}
                        </label>
                      </div>
                    </div>
                    {/* check if active fabric value is empty */}
                    <div className="product-header__dropdowns__item ">
                      <div className="input-wrapper  ">
                        {/* dropdown / select */}
                        <div className="input-dropdown">
                          <select
                            onChange={(e) => setselectedfabric(e.target.value)}
                            value={selectedfabric}
                            name="fabric"
                            id="fabric"
                            className="input js-product-header__fabric"
                          >
                            <option value="1" data-colours='["Gold"]'>
                              PBI® NEO®
                            </option>
                            <option
                              value="0"
                              selected
                              data-colours='["Dark Blue"]'
                            >
                              Nomex® NXT
                            </option>
                          </select>
                          <div className="icon input-dropdown__icon">
                            <svg>
                              <use xlinkHref="/symbols.svg#icon-chevron-down" />
                            </svg>
                          </div>{" "}
                        </div>
                        <label htmlFor="fabric" className="input-label">
                          {/* checkbox icon  */}
                          Out layer{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* size */}
                  <div className="product-header__size">
                    <span className="text text--bold text--small text--white">
                      {" "}
                      Sizes:{" "}
                    </span>
                    {/* <span class="product-header__size__value"> XS - 4XL</span> */}
                    <span className="js-product-header__size text text--small">
                      XS - 4XL
                    </span>
                  </div>
                  {/* code */}
                  <div className="product-header__code">
                    <span className="text text--bold text--small text--white">
                      Composition code:
                    </span>
                    <span className="text text--small">
                      {/* set active product code */}
                      <span className="js-product-header__code-structure">
                        ZA
                      </span>
                      <span className="js-product-header__code-fabric">16</span>
                    </span>
                  </div>
                  {/* articlenumber */}
                  <span className="text text--bold text--small text--white">
                    {" "}
                    Article no.:{" "}
                  </span>
                  <span className="js-product-header__articlenumber text text--small">
                    {" "}
                    12865
                  </span>
                  {/* colours */}
                  {/* show active fabric colours */}
                  {/* <div class="product-header__content__tab js-product-header__content-fabric  " data-fabric="pbi-neo"> */}
                  <div
                    className="product-header__content__tab js-product-header__content-fabric  "
                    data-fabric="pbi-neo"
                  >
                    <div className="product-header__colours">
                      <div className="product-header__label input-label">
                        Color
                      </div>
                      {/* <div class="product-header__colours__item js-product-header__colour-picker  " title="Gold" data-colour-name="Gold" data-codes='[{"structure":"x-treme","structure_term_id":49,"code":"15","active":false}]' data-certificates='[{"structure":"x-treme","structure_term_id":49,"label":"EN 469:2020 X2 Y2 Z2, EN 1149-5:2018, EN ISO 11612:2015 A1 B1 C1"}]' data-articlenumbers='[{"active":false,"structure":"x-treme","structure_term_id":49,"number":"12126"}]'> */}
                      <div
                        className="product-header__colours__item js-product-header__colour-picker  active"
                        title="Gold"
                        data-colour-name="Gold"
                        data-size="XS - 4XL"
                        data-codes='[{"structure":"x-treme","structure_term_id":49,"code":"15","active":false}]'
                        data-sizes="XS - 4XL"
                        data-certificates='[{"structure":"x-treme","structure_term_id":49,"label":"EN 469:2020 X2 Y2 Z2, EN 1149-5:2018, EN ISO 11612:2015 A1 B1 C1"}]'
                        data-articlenumbers='[{"active":false,"structure":"x-treme","structure_term_id":49,"number":"12126"}]'
                      >
                        <div className="product-header__colours__item__visual">
                          <img
                            data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_PBI_NEO_gold_ZA15_FL.png"
                            alt="Gold"
                            className="lazyload"
                          />
                        </div>
                        Gold{" "}
                      </div>
                    </div>
                  </div>
                  {/* show active fabric colours */}
                  {/* <div class="product-header__content__tab js-product-header__content-fabric active " data-fabric="nomex-nxt"> */}
                  <div
                    className="product-header__content__tab js-product-header__content-fabric active "
                    data-fabric="nomex-nxt"
                  >
                    <div className="product-header__colours">
                      <div className="product-header__label input-label">
                        Color
                      </div>
                      {/* <div class="product-header__colours__item js-product-header__colour-picker visible active" title="Dark Blue" data-colour-name="Dark Blue" data-codes='[{"structure":"x-treme","structure_term_id":49,"code":"16","active":true},{"structure":"x-treme-light","code":"16","active":false}]' data-certificates='[{"structure":"x-treme","structure_term_id":49,"label":"EN 1149-5:2018, EN 469:2020 X2 Y2 Z2, EN ISO 11612:2015 A1 B1 C1"},{"structure":"x-treme-light","label":"EN 1149-5:2018, EN 469:2020 X2 Y2 Z2, EN ISO 11612:2015 A1 B1 C1"}]' data-articlenumbers='[{"active":true,"structure":"x-treme","structure_term_id":49,"number":"12865"},{"active":false,"structure":"x-treme-light","number":"12268"}]'> */}
                      <div
                        className="product-header__colours__item js-product-header__colour-picker visible active"
                        title="Dark Blue"
                        data-colour-name="Dark Blue"
                        data-size="XS - 4XL"
                        data-codes='[{"structure":"x-treme","structure_term_id":49,"code":"16","active":true},{"structure":"x-treme-light","code":"16","active":false}]'
                        data-sizes="XS - 4XL"
                        data-certificates='[{"structure":"x-treme","structure_term_id":49,"label":"EN 1149-5:2018, EN 469:2020 X2 Y2 Z2, EN ISO 11612:2015 A1 B1 C1"},{"structure":"x-treme-light","label":"EN 1149-5:2018, EN 469:2020 X2 Y2 Z2, EN ISO 11612:2015 A1 B1 C1"}]'
                        data-articlenumbers='[{"active":true,"structure":"x-treme","structure_term_id":49,"number":"12865"},{"active":false,"structure":"x-treme-light","number":"12268"}]'
                      >
                        <div className="product-header__colours__item__visual">
                          <img
                            data-src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FL.png"
                            alt="Dark Blue"
                            className=" ls-is-cached lazyloaded"
                            src="https://www.texport.at/en/wp-content/uploads/products/fire-twin/images/Fire_Twin_Hose_NOMEX_NXT_dblau_ZA16_FL.png"
                          />
                        </div>
                        Dark Blue{" "}
                      </div>
                    </div>
                  </div>
                  {/* button */}
                  <div className="product-header__button">
                    <a
                      className="button button--red js-product-header__sales-link js-ga-track js-ga4-track"
                      href="#"
                      data-ga-event-category="Product"
                      data-ga-event-label="Contact a sales representative"
                      data-ga-event-action="Fire Twin Trousers"
                      data-ga4-event="contact_sales_representative"
                    >
                      <div className="icon button__icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                          viewBox="0 0 32 32"
                        >
                          <path
                            fill="#FFF"
                            fillRule="evenodd"
                            d="M24.5 9.5a.5.5 0 01.492.41L25 10v12a.5.5 0 01-.41.492l-.09.008h-17a.5.5 0 01-.492-.41L7 22V10a.5.5 0 01.41-.492L7.5 9.5h17zm-6.278 7.211l-1.89 1.833a.5.5 0 01-.589.055l-.075-.055-1.891-1.833L8.749 21.5H23.25l-5.028-4.789zM24 11.113l-5.059 4.902L24 20.834v-9.721zm-16 0v9.72l5.058-4.818L8 11.113zm15.184-.613H8.815l7.185 7 7.184-7z"
                          />
                        </svg>
                      </div>{" "}
                      <span> Contact a sales representative</span>
                    </a>
                  </div>
                  {/* download link */}
                  {/* show active state */}
                  <div
                    className="product-header__content__tab js-product-header__content-fabric "
                    data-fabric="pbi-neo"
                  >
                    <div className="product-header__download-links">
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Care instructions"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Care instructions
                        </span>
                      </a>
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Product brochures"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Product brochures
                        </span>
                      </a>
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Composition matrix"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Composition matrix
                        </span>
                      </a>
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Length scale chart"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Length scale chart
                        </span>
                      </a>
                    </div>
                  </div>
                  {/* show active state */}
                  <div
                    className="product-header__content__tab js-product-header__content-fabric active"
                    data-fabric="nomex-nxt"
                  >
                    <div className="product-header__download-links">
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Care instructions"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Care instructions
                        </span>
                      </a>
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Product brochures"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Product brochures
                        </span>
                      </a>
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Composition matrix"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Composition matrix
                        </span>
                      </a>
                      <a
                        href="#"
                        className="product-header__download-links__item js-ga-track"
                        download
                        target="_blank"
                        data-ga-event-label="Download: Length scale chart"
                        data-ga-event-action="Fire Twin Trousers"
                      >
                        <div className="icon product-header__download-links__icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={32}
                            height={32}
                            viewBox="0 0 32 32"
                          >
                            <path
                              fill="#FFF"
                              fillRule="evenodd"
                              d="M26 19.5a.5.5 0 01.492.41l.008.09v5.5h-21V20a.5.5 0 01.992-.09L6.5 20v4.5h19V20a.5.5 0 01.41-.492L26 19.5zm-10-13a.5.5 0 01.492.41L16.5 7v12.293l5.146-5.147a.5.5 0 01.638-.057l.07.057a.5.5 0 01.057.638l-.057.07-6 6a.5.5 0 01-.638.057l-.07-.057-6-6a.5.5 0 01.638-.765l.07.057 5.146 5.147V7a.5.5 0 01.5-.5z"
                            />
                          </svg>
                        </div>{" "}
                        <span className="text text--small">
                          Length scale chart
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Info;
