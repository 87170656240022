import { useEffect, useRef, useState } from "react";
import logo from "../../img/logo-footer.png";
import { Link } from "react-router-dom";
function Header() {
  const [isopendmobile, setisopendmobile] = useState(false);
  const [ismobile, setismobile] = useState(false);
  const subfirsuit = useRef(null);
  const submedical = useRef(null);
  const submiliyary = useRef(null);
  const [selectheader, setselectheader] = useState(-1);
  const inputRef = useRef();

  useEffect(() => {
    const size = window
      .getComputedStyle(inputRef.current, null)
      .getPropertyValue("font-size");

    console.log("fontsize: " + size.replace("px", ""));
    setismobile(() => window.innerWidth / size.replace("px", "") <= 61.24);
  }, []);
  //  const ismobile = window.innerWidth/fontsize;
  //  console.log("fontsize: "+ fontsize);
  return (
    <div
      ref={inputRef}
      className="module module--navigation  "
      data-name="navigation"
    >
      <div className="module__content">
        <div
          className={"navigation " + (isopendmobile ? "mobile-nav-active" : "")}
        >
          <div className="navigation__content container">
            <Link to={"/"} className="navigation__logo">
              <img
                alt="Texport"
                className="navigation__logo__image navigation__logo__image--desktop lazyloaded"
                src={logo}
              />
              <img
                src={logo}
                alt="Texport"
                className="navigation__logo__image--mobile lazyloaded"
              />
            </Link>
            <div className="navigation__main">
              <nav
                className="navigation__items"
                onMouseLeave={() => setselectheader(-1)}
              >
                <div
                  className={
                    "navigation__item  js-navigation__item" +
                    (selectheader === 1
                      ? ismobile
                        ? " mobile-subnav-open"
                        : " desktop-subnav-open keep"
                      : "")
                  }
                  onMouseEnter={(e) => setselectheader(1)}
                >
                  <a className="navigation__item__link  js-ga-track js-ga4-track js-navigation__link">
                    Products{" "}
                    <div className="icon navigation__item__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-chevron-right" />
                      </svg>
                    </div>{" "}
                  </a>
                  {/* subnavigation */}
                  <div className="navigation__subnavigation">
                    <div>
                      <div className="navigation__subnavigation__container container">
                        {/* back */}
                        <div className="navigation__subnavigation__back js-navigation__back">
                          <div className="icon navigation__subnavigation__back__icon">
                            <svg>
                              <use xlinkHref="/symbols.svg#icon-chevron-left" />
                            </svg>
                          </div>{" "}
                          Products{" "}
                        </div>
                        <div className="navigation__subnavigation__column navigation__subnavigation__column--default border">
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  FireSuit
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                              <div
                                onClick={() =>
                                  subfirsuit.current.style.display == "block"
                                    ? (subfirsuit.current.style.display =
                                        "none")
                                    : (subfirsuit.current.style.display =
                                        "block")
                                }
                                className="navigation__subnavigation__mainlink__plus"
                              />
                            </div>
                            {/* sublinks */}
                            <div
                              ref={subfirsuit}
                              className="navigation__subnavigation__sublinks js-accordion__body"
                            >
                              <div onClick={()=>setisopendmobile(false)}  className="navigation__subnavigation__sublinks__item">
                                <div>
                                  <Link
                                    to={"/product/firsout/Trousers"}
                                    className="navigation__subnavigation__sublink js-ga-track js-ga4-track"
                                  >
                                    Remal Suit Trouser
                                  </Link>
                                </div>
                                <div>
                                  <a target="_self">Remal Suit Jacket</a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  Military clothing
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                              <div
                                onClick={() =>
                                  submiliyary.current.style.display == "block"
                                    ? (submiliyary.current.style.display =
                                        "none")
                                    : (submiliyary.current.style.display =
                                        "block")
                                }
                                className="navigation__subnavigation__mainlink__plus"
                              />
                            </div>
                            {/* sublinks */}
                            <div
                              ref={submiliyary}
                              className="navigation__subnavigation__sublinks js-accordion__body"
                            >
                              <div className="navigation__subnavigation__sublinks__item" onClick={()=>setisopendmobile(false)} >
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 1
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 2
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 3
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 4
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 5
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 6
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 7
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Military clothing 8
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="navigation__subnavigation__column navigation__subnavigation__column--default ">
                          <div
                            className="navigation__subnavigation__column__item"
                            data-accordion-settings='{"until": "tablet-landscape"}'
                          >
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  Medical
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                              <div
                                onClick={() =>
                                  submedical.current.style.display == "block"
                                    ? (submedical.current.style.display =
                                        "none")
                                    : (submedical.current.style.display =
                                        "block")
                                }
                                className="navigation__subnavigation__mainlink__plus"
                              />
                            </div>
                            {/* sublinks */}
                            <div
                              ref={submedical}
                              className="navigation__subnavigation__sublinks js-accordion__body"
                            >
                              <div className="navigation__subnavigation__sublinks__item" onClick={()=>setisopendmobile(false)} >
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Medical Product 1
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Medical Product 2
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Medical Product 3
                                  </a>
                                </div>
                                <div>
                                  <a className="navigation__subnavigation__sublink js-ga-track js-ga4-track">
                                    Medical Product 4
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*
                sdsd
                */}
                <div
                  className={
                    "navigation__item  js-navigation__item" +
                    (selectheader === 3
                      ? ismobile
                        ? " mobile-subnav-open"
                        : " desktop-subnav-open keep"
                      : "")
                  }
                  onMouseEnter={(e) => setselectheader(3)}
                >
                  <a className="navigation__item__link  js-ga-track js-ga4-track js-navigation__link">
                    About us{" "}
                    <div className="icon navigation__item__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-chevron-right" />
                      </svg>
                    </div>{" "}
                  </a>
                  {/* subnavigation */}
                  <div className="navigation__subnavigation">
                    <div>
                      <div className="navigation__subnavigation__container container">
                        {/* back */}
                        <div className="navigation__subnavigation__back js-navigation__back">
                          <div className="icon navigation__subnavigation__back__icon">
                            <svg>
                              <use xlinkHref="/symbols.svg#icon-chevron-left" />
                            </svg>
                          </div>{" "}
                          About us{" "}
                        </div>
                        <div onClick={()=>setisopendmobile(false)} className="navigation__subnavigation__column navigation__subnavigation__column--default ">
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head"
                            
                            >
                              <Link
                                to={"/vision"}
                                className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link"
                              >
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  Vision
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </Link>
                            </div>
                          </div>
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  Historie
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                            </div>
                            {/* sublinks */}
                          </div>
                        </div>
                        <div onClick={()=>setisopendmobile(false)} className="navigation__subnavigation__column navigation__subnavigation__column--default ">
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <Link
                                to={"/contact"}
                                className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link"
                              >
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  Contact person
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </Link>
                            </div>
                            {/* sublinks */}
                          </div>
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <Link
                                to={"/Partener"}
                                className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link"
                              >
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  Partners
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </Link>
                            </div>
                            {/* sublinks */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    "navigation__item  js-navigation__item" +
                    (selectheader === 4
                      ? ismobile
                        ? " mobile-subnav-open"
                        : " desktop-subnav-open keep"
                      : "")
                  }
                  onMouseEnter={(e) => setselectheader(4)}
                >
                  <a className="navigation__item__link  js-ga-track js-ga4-track js-navigation__link">
                    Technology{" "}
                    <div className="icon navigation__item__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-chevron-right" />
                      </svg>
                    </div>{" "}
                  </a>
                  {/* subnavigation */}
                  <div className="navigation__subnavigation">
                    <div>
                      <div className="navigation__subnavigation__container container">
                        {/* back */}
                        <div className="navigation__subnavigation__back js-navigation__back">
                          <div className="icon navigation__subnavigation__back__icon">
                            <svg>
                              <use xlinkHref="/symbols.svg#icon-chevron-left" />
                            </svg>
                          </div>{" "}
                          Technology{" "}
                        </div>
                        <div className="navigation__subnavigation__column navigation__subnavigation__column--default " onClick={()=>setisopendmobile(false)} >
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  *******************
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                            </div>
                            {/* sublinks */}
                          </div>
                        </div>
                        <div className="navigation__subnavigation__column navigation__subnavigation__column--default " onClick={()=>setisopendmobile(false)} >
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  *****************{" "}
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                            </div>
                            {/* sublinks */}
                          </div>
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  ****************
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                            </div>
                            {/* sublinks */}
                          </div>
                        </div>
                        <div className="navigation__subnavigation__column navigation__subnavigation__column--default " onClick={()=>setisopendmobile(false)} >
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link">
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  **************
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                            </div>
                            {/* sublinks */}
                          </div>
                          <div className="navigation__subnavigation__column__item">
                            {/* main link */}
                            <div className="navigation__subnavigation__mainlink js-accordion__head">
                              <a
                                className="js-ga-track  js-ga4-track navigation__subnavigation__mainlink__arrow-link"
                                data-ga4-event="navigation"
                                data-ga4-parameter1-name="link_to"
                                data-ga4-parameter1-value="en/downloads/"
                                data-ga-event-category="Navigation"
                                data-ga-event-label="Technology: Downloads"
                                href="https://www.texport.at/en/downloads/"
                                target="_self"
                              >
                                <div className="js-ga-track navigation__subnavigation__mainlink__label">
                                  Downloads
                                </div>
                                <div className="icon navigation__subnavigation__mainlink__icon no-sublinks">
                                  <svg>
                                    <use xlinkHref="/symbols.svg#icon-chevron-right" />
                                  </svg>
                                </div>{" "}
                              </a>
                            </div>
                            {/* sublinks */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {}
                <div className="navigation__item  js-navigation__item" onClick={()=>setisopendmobile(false)} >
                  <Link
                    to={"/contact"}
                    className="navigation__item__link  js-ga-track js-ga4-track js-navigation__link"
                  >
                    Contact{" "}
                    <div className="icon navigation__item__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-chevron-right" />
                      </svg>
                    </div>{" "}
                  </Link>
                </div>
              </nav>
              {/* mobile language selector */}
              <div className="navigation__languages navigation__languages--mobile">
                <div className="navigation__languages__link js-accordion__head">
                  <div className="navigation__languages__title">Language:</div>
                  <div className="navigation__languages__current">EN</div>
                  <div className="icon navigation__languages__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-chevron-down" />
                    </svg>
                  </div>{" "}
                </div>
                <div className="navigation__languages__dropdown js-accordion__body">
                  <div className="navigation__languages__item">
                    <a
                      className="navigation__languages__item__link"
                      href="https://www.texport.at/de/products/fire-twin-hose/"
                    >
                      DE
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="navigation__side">
              <div className="navigation__search">
                <div className="navigation__search__inner">
                  <div className="icon navigation__search__icon js-navigation__search-trigger">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-search" />
                    </svg>
                  </div>{" "}
                  <input
                    type="text"
                    className="navigation__search__input js-navigation__search-input"
                    placeholder="Enter search term"
                    data-redirect-url="https://www.texport.at/en/?s=#QUERY#"
                    data-replace-query-string="#QUERY#"
                  />
                  <div className="navigation__search__title">Search </div>
                  <div className="icon navigation__search__icon navigation__search__icon--close js-navigation__search-close">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-close" />
                    </svg>
                  </div>{" "}
                </div>
              </div>
              {/* desktop language selector */}
              <div className="navigation__languages navigation__languages--desktop js-navigation__item">
                {/*<div className="navigation__languages__link js-navigation__link">
                  <div className="navigation__languages__current">EN</div>
                  <div className="icon navigation__languages__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-chevron-down" />
                    </svg>
                  </div>{" "}
                </div>*/}
                <div className="navigation__languages__dropdown">
                  <div className="navigation__languages__item">
                    <a
                      className="navigation__languages__item__link"
                      href="https://www.texport.at/de/products/fire-twin-hose/"
                    >
                      DE
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="navigation__mobiletoggle js-navigation__mobiletoggle"
                onClick={() =>
                  setisopendmobile((isopendmobile) => !isopendmobile)
                }
              >
                <span />
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
        <div className="navigation__spacer" />
      </div>
    </div>
  );
}
export default Header;
