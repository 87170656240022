import logo from "./logo.svg";
import "./App.css";
import "./style.css";
import Header from "./composant/Header/Header";
import SubHeader from "./composant/Header/SubHeader";
import Social from "./composant/Header/Social";
import Footer from "./composant/Footer/Footer";
import FireSoot from "./composant/Pages/Firesuit.js/FireSoot";
import Contact from "./composant/Pages/constact/Constact";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Partners from "./composant/Pages/Partener/Partener";
import Vision from "./composant/Pages/Vision/Vision";
import Home from "./composant/Pages/Home/Home";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />

        <Header></Header>
        {/*<FireSoot></FireSoot>*/}
        <Routes>
          <Route
            path={"/product/firsout/Trousers"}
            element={<FireSoot />}
          ></Route>
          <Route path={"/"} element={<Home />}></Route>

          <Route path={"/contact"} element={<Contact />}></Route>
          <Route path={"/Partener"} element={<Partners />}></Route>
          <Route path={"/vision"} element={<Vision />}></Route>
        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </>
  );
}

export default App;
