import img1 from "./saudi-army.jpg";
import img2 from "./word.png";
function Vision() {
  return (
    <>
      <div
        class="module module--headline module--space-top-big module--space-bottom-tiny "
        data-name="headline"
      >
        <div class="module__content">
          <div class="headline__outer container--medium">
            <div class="headline container container--small">
              <div class="headline__uptitle">
                <div class="uptitle ">
                  <div class="icon uptitle__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-texport-logo"></use>
                    </svg>
                  </div>{" "}
                  A vision precedes every mission{" "}
                </div>
              </div>

              <div class=" headline__title h1 ">
                For Sondos Advanced<sup>®</sup>, it all began with a vision:{" "}
              </div>

              <div class="headline__subtitle h4 ">
                “I want to make Sondos Advanced<sup>®</sup> No. 1 at the
                Kindom!”, Tarfa *****, founder and CEO of Sondos Advanced
                <sup>®</sup>.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="module module--text-picture-full  ">
        <div className="module__content">
          <div className="text-picture-full picture-full  ">
            <div className="text-picture-full__picture ">
              <img
                src={img1}
                className="mobile lazyloaded text-picture-full__image"
              />
              <img
                src={img1}
                className="tablet lazyloaded text-picture-full__image"
              />
              <img
                className="desktop text-picture-full__image lazyloaded"
                src={img1}
              />
              <img
                src={img1}
                alt=""
                className="hd lazyloaded text-picture-full__image"
              />
            </div>
            <div className="text-picture-full__caption  container--small">
              <div className="text-picture-full__caption__inner">
                <div className="text-picture-full__title h1">
                  Always There for You{" "}
                </div>
                {/* <div class="text-picture-full__text text "> */}
                <div className="text-picture-full__text text">
                  <p>
                    The ideal product solution requires a detailed advisory
                    conversation, where all your demands and wishes can be put
                    to us. Therefore, our sales representatives are out in the
                    field internationally, to look after our customers and
                    business partners. We also design individual pieces of
                    clothing that conform 100 % to the customer’s
                    specifications. If a repair should be necessary, then of
                    course we will see it as our responsibility to provide this
                    service as quickly as possible. We are available for you at
                    all times through our extensive service and trade network.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="module module--headline module--space-top-big module--space-bottom-tiny ">
        <div className="module__content">
          <div className="headline__outer container--medium">
            <div className="headline container container--small">
              <div className="headline__uptitle">
                <div className="uptitle ">
                  <div className="icon uptitle__icon">
                    <svg>
                      <use xlinkHref="/symbols.svg#icon-texport-logo" />
                    </svg>
                  </div>{" "}
                  Performance and Fit{" "}
                </div>
              </div>
              <div className=" headline__title h1 ">Our Promise to You </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module module--text module--space-big " data-name="text">
        <div class="module__content">
          <div class="headline container container--small">
            <div class="headline__text text text  ">
              <p>
                We don’t just provide a product that offers the best possible
                protection – we provide a product that fulfils the highest
                demands in terms of comfort, fit and ergonomics. Even at the
                beginning of the development process, close attention is paid to
                the best possible fit and comfort. There, we not only keep an
                eye on body types and shapes of the wearers, but work on the
                best solutions to the smallest detail – from the position of the
                pockets to the arrangement of the Velcro. Our bespoke sizing
                system also makes it possible to optimally adapt our clothing to
                nearly all wearers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        className="module module--text-picture module--space-big bg-black"
        data-name="text-picture"
      >
        <div className="module__content">
          <div className="text-picture text-picture--left ">
            <div className="text-picture__inner container ">
              {/* caption */}
              <div className="text-picture__caption">
                <div className="text-picture__uptitle">
                  <div className="uptitle ">
                    <div className="icon uptitle__icon">
                      <svg>
                        <use xlinkHref="/symbols.svg#icon-texport-logo" />
                      </svg>
                    </div>{" "}
                    All from One Place{" "}
                  </div>
                </div>
                <div className="text-picture__title h2"></div>
                <div className="text-picture__subtitle text text--heavy">
                  From Idea to Product{" "}
                </div>
                <div className="text-picture__text text">
                  <p>
                    Serial production only begins after intensive research into
                    the design and material composition has been carried out and
                    practical tests have been passed. There, the clothing is
                    meticulously and elaborately handcrafted by trained workers.
                  </p>
                </div>
              </div>
              {/* image */}
              <div className="text-picture__picture">
                <img
                  alt=""
                  className="desktop text-picture__image ls-is-cached lazyloaded"
                  src={img2}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Vision;
