import ReactPlayer from "react-player";
import videheader from "../../../video/HomeHeader1.mp4";
import ProductSlider from "./ProductSlider";
import Vision from "./Vision";
function Home() {
  return (
    <>
      <div
        className="module module--header-landing module--space-bottom-big video-playing"
        data-name="header-landing"
      >
        <div className="module__content">
          <div
            className="header-landing"
            data-slider-settings='{"autoHeight": false, "slidesPerView": 1, "navigation": "1", "pagination": true, "paginationType": "fraction", "parallax": true, "speed": 500, "customLoop": true}'
            data-autoplay=""
          >
            <div className="header-landing__slide">
              <div className="header-landing__media">
                <div className="header-landing__video desktop">
                  <video
                    autoPlay
                    muted
                    loop
                    className="inline-video video-loaded"
                    src={videheader}
                  ></video>
                </div>
                <div className="header-landing__video mobile">
                  <video
                    autoPlay
                    muted
                    loop
                    className="inline-video video-loaded"
                    src={videheader}
                  ></video>
                </div>
                <img
                  data-src=""
                  alt=""
                  className="mobile lazyload header-landing__image"
                />
                <img
                  data-src=""
                  alt=""
                  className="tablet lazyload header-landing__image"
                />
                <img
                  data-src=""
                  alt=""
                  className="desktop header-landing__image lazyloaded"
                />
                <img
                  data-src=""
                  alt=""
                  className="hd lazyload header-landing__image"
                />
              </div>
              <div className="header-landing__container container container--medium">
                <div
                  className="header-landing__content"
                  data-swiper-parallax={-300}
                >
                  <div className="header-landing__uptitle">
                    <div className="uptitle ">
                      <div className="icon uptitle__icon">
                        <svg>
                          <use xlinkHref="/symbols.svg#icon-texport-logo" />
                        </svg>
                      </div>{" "}
                      Premiere{" "}
                    </div>
                  </div>
                  <h1 className="header-landing__title h1">New products</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductSlider></ProductSlider>
      <Vision></Vision>
    </>
  );
}
export default Home;
